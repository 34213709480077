import React, { useState } from 'react';
import { 
  CreditCard, 
  Check, 
  ArrowRight, 
  Clock, 
  Zap, 
  Bookmark 
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, setDoc, doc } from 'firebase/firestore';
import { app } from '../../firebaseConfig';
import { useAuth } from '../../context/AuthContext';

const PaymentPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: ''
  });

  const plans = [
    {
      id: 'single',
      name: 'Single Course',
      price: 99,
      icon: <Bookmark className="text-blue-500" size={24} />,
      features: ['Access to one selected course', 'Lifetime course materials']
    },
    {
      id: 'monthly',
      name: 'Monthly Subscription',
      price: 49,
      icon: <Clock className="text-green-500" size={24} />,
      features: ['Unlimited course access', 'Monthly billing', 'Cancel anytime']
    },
    {
      id: 'yearly',
      name: 'Yearly Subscription',
      price: 499,
      icon: <Zap className="text-purple-500" size={24} />,
      features: ['Unlimited course access', 'Best value', 'Save 30%', 'Priority support']
    }
  ];

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const handleCardDetailChange = (e) => {
    const { name, value } = e.target;
    setCardDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const calculateTotal = () => {
    if (!selectedPlan) return 0;
    const subtotal = selectedPlan.price;
    const tax = subtotal * 0.1; // 10% tax
    return {
      subtotal,
      tax: Math.round(tax),
      total: Math.round(subtotal + tax)
    };
  };

  const handlePayment = async () => {
    const db = getFirestore(app);
    
    try {
      await setDoc(doc(db, 'users', user.uid), {
        accountStatus: 'active',
        paymentCompleted: true,
        selectedPlan: selectedPlan.name,
        subscriptionDate: new Date()
      }, { merge: true });

      navigate('/dashboard');
    } catch (error) {
      console.error('Payment processing failed', error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center p-8">
      <div className="bg-white shadow-2xl rounded-2xl max-w-4xl w-full grid grid-cols-2 overflow-hidden">
        {/* Plan Selection Section */}
        <div className="p-8 bg-gray-50 border-r">
          <h2 className="text-3xl font-bold mb-6 text-gray-800 flex items-center">
            <CreditCard className="mr-3 text-indigo-500" size={32} />
            Select Your Plan
          </h2>
          <div className="space-y-4">
            {plans.map((plan) => (
              <div 
                key={plan.id}
                onClick={() => handlePlanSelect(plan)}
                className={`
                  border rounded-lg p-4 cursor-pointer transition-all duration-300
                  ${selectedPlan?.id === plan.id 
                    ? 'border-indigo-500 bg-indigo-50 shadow-md' 
                    : 'border-gray-200 hover:border-gray-300'}
                `}
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    {plan.icon}
                    <div className="ml-4">
                      <h3 className="font-semibold text-gray-800">{plan.name}</h3>
                      <p className="text-gray-600 text-sm">${plan.price} {plan.id !== 'single' ? '/ month' : ''}</p>
                    </div>
                  </div>
                  {selectedPlan?.id === plan.id && (
                    <Check className="text-indigo-500" size={24} />
                  )}
                </div>
                <ul className="mt-2 text-sm text-gray-600 space-y-1">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <ArrowRight className="mr-2 text-green-500" size={16} />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Payment Details Section */}
        <div className="p-8">
          {selectedPlan ? (
            <>
              <h2 className="text-2xl font-bold mb-6 text-gray-800">Payment Details</h2>
              <div className="space-y-4">
                <div>
                  <label className="block mb-2 text-gray-700">Card Number</label>
                  <input 
                    type="text" 
                    name="cardNumber"
                    value={cardDetails.cardNumber}
                    onChange={handleCardDetailChange}
                    placeholder="1234 5678 9012 3456"
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block mb-2 text-gray-700">Expiry Month</label>
                    <input 
                      type="text" 
                      name="expiryMonth"
                      value={cardDetails.expiryMonth}
                      onChange={handleCardDetailChange}
                      placeholder="MM"
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-gray-700">Expiry Year</label>
                    <input 
                      type="text" 
                      name="expiryYear"
                      value={cardDetails.expiryYear}
                      onChange={handleCardDetailChange}
                      placeholder="YY"
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                    />
                  </div>
                </div>
                <div>
                  <label className="block mb-2 text-gray-700">CVV</label>
                  <input 
                    type="text" 
                    name="cvv"
                    value={cardDetails.cvv}
                    onChange={handleCardDetailChange}
                    placeholder="123"
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
              </div>

              {/* Order Summary */}
              <div className="mt-8 bg-gray-50 p-4 rounded-lg">
                <h3 className="text-xl font-semibold mb-4 text-gray-800">Order Summary</h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span>Plan: {selectedPlan.name}</span>
                    <span>${selectedPlan.price}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>Tax (10%)</span>
                    <span>${calculateTotal().tax}</span>
                  </div>
                  <div className="border-t pt-2 flex justify-between font-bold">
                    <span>Total</span>
                    <span>${calculateTotal().total}</span>
                  </div>
                </div>
                <button 
                  onClick={handlePayment}
                  className="w-full mt-4 bg-indigo-500 text-white py-3 rounded-lg hover:bg-indigo-600 transition-colors"
                >
                  Complete Payment
                </button>
              </div>
            </>
          ) : (
            <div className="text-center text-gray-500">
              <p>Select a plan to proceed with payment</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;