import React from 'react';
import { 
  Users, BookOpen, TrendingUp, DollarSign, 
  Clock, Award, Calendar, Activity 
} from 'lucide-react';

const StatCard = ({ title, value, icon: Icon, trend, color }) => (
  <div className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-500 mb-1">{title}</p>
        <h3 className="text-2xl font-semibold">{value}</h3>
        {trend && (
          <p className={`text-sm mt-2 ${trend > 0 ? 'text-green-600' : 'text-red-600'}`}>
            {trend > 0 ? '↑' : '↓'} {Math.abs(trend)}% from last month
          </p>
        )}
      </div>
      <div className={`p-3 rounded-full ${color}`}>
        <Icon className="w-6 h-6 text-white" />
      </div>
    </div>
  </div>
);

const RecentActivityItem = ({ user, action, time, course }) => (
  <div className="flex items-center gap-4 py-3">
    <div className="flex-shrink-0">
      <img 
        src={user.avatar || '/api/placeholder/32/32'} 
        alt={user.name}
        className="w-8 h-8 rounded-full"
      />
    </div>
    <div className="flex-grow min-w-0">
      <p className="text-sm text-gray-900 truncate">
        <span className="font-medium">{user.name}</span> {action}
      </p>
      {course && (
        <p className="text-sm text-gray-500 truncate">{course}</p>
      )}
    </div>
    <div className="flex-shrink-0 text-sm text-gray-500">
      {time}
    </div>
  </div>
);

const AdminDashboard = () => {
  // Sample data - replace with real data
  const stats = [
    { 
      title: 'Total Students', 
      value: '2,845', 
      icon: Users, 
      trend: 12,
      color: 'bg-indigo-500' 
    },
    { 
      title: 'Active Courses', 
      value: '24', 
      icon: BookOpen, 
      trend: 8,
      color: 'bg-blue-500' 
    },
    { 
      title: 'Monthly Revenue', 
      value: '$28,450', 
      icon: DollarSign, 
      trend: 15,
      color: 'bg-green-500' 
    },
    { 
      title: 'Course Completion', 
      value: '76%', 
      icon: Award, 
      trend: -3,
      color: 'bg-purple-500' 
    }
  ];

  const recentActivity = [
    {
      user: { name: 'John Doe', avatar: null },
      action: 'enrolled in Advanced JavaScript',
      time: '5m ago',
      course: 'Advanced JavaScript'
    },
    // Add more activities...
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Dashboard Overview</h1>
          <p className="text-gray-500">Welcome back, Admin</p>
        </div>
        <button className="btn bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors">
          Generate Report
        </button>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat, index) => (
          <StatCard key={index} {...stat} />
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Charts Section */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Enrollment Trends</h2>
          <div className="h-64">
            {/* Add your chart component here */}
            <p className="text-gray-500">Chart placeholder</p>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Recent Activity</h2>
            <button className="text-indigo-600 hover:text-indigo-800 text-sm">
              View All
            </button>
          </div>
          <div className="divide-y">
            {recentActivity.map((activity, index) => (
              <RecentActivityItem key={index} {...activity} />
            ))}
          </div>
        </div>

        {/* Quick Actions */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Quick Actions</h2>
          <div className="grid grid-cols-2 gap-4">
            <button className="p-4 border rounded-lg hover:bg-gray-50 transition-colors text-left">
              <Calendar className="w-6 h-6 text-indigo-600 mb-2" />
              <h3 className="font-medium">Schedule Course</h3>
              <p className="text-sm text-gray-500">Create new course sessions</p>
            </button>
            <button className="p-4 border rounded-lg hover:bg-gray-50 transition-colors text-left">
              <Users className="w-6 h-6 text-blue-600 mb-2" />
              <h3 className="font-medium">Add Student</h3>
              <p className="text-sm text-gray-500">Enroll new students</p>
            </button>
            {/* Add more quick actions */}
          </div>
        </div>

        {/* Upcoming Sessions */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Upcoming Sessions</h2>
          <div className="space-y-4">
            {/* Add upcoming sessions list */}
            <div className="flex items-center gap-4 p-3 bg-gray-50 rounded-lg">
              <Clock className="w-5 h-5 text-gray-500" />
              <div>
                <p className="font-medium">Web Development Basics</p>
                <p className="text-sm text-gray-500">Today at 2:00 PM</p>
              </div>
            </div>
            {/* Add more sessions */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;