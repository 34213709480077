import React, { useState } from 'react';
import { 
  PlusCircle, Search, Filter, MoreVertical, FileText,
  Users, Clock, DollarSign, Star, Play, Book
} from 'lucide-react';

const CourseCard = ({ course }) => (
  <div className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
    <div className="relative aspect-video rounded-t-lg overflow-hidden bg-gray-100">
      <img 
        src={course.thumbnail || '/api/placeholder/400/225'} 
        alt={course.title}
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
      <div className="absolute bottom-4 left-4 right-4">
        <p className="text-white font-semibold truncate">{course.title}</p>
        <div className="flex items-center gap-2 text-white/90 text-sm mt-1">
          <Users className="w-4 h-4" />
          <span>{course.enrollments} students</span>
        </div>
      </div>
    </div>
    
    <div className="p-4">
      <div className="flex items-center gap-2 text-sm text-gray-500 mb-3">
        <span className="flex items-center gap-1">
          <Clock className="w-4 h-4" />
          {course.duration}
        </span>
        <span className="flex items-center gap-1">
          <Play className="w-4 h-4" />
          {course.lessons} lessons
        </span>
        <span className="flex items-center gap-1">
          <Star className="w-4 h-4 text-yellow-400" />
          {course.rating}
        </span>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <img 
            src={course.instructor.avatar || '/api/placeholder/32/32'}
            alt={course.instructor.name}
            className="w-8 h-8 rounded-full"
          />
          <div className="text-sm">
            <p className="font-medium">{course.instructor.name}</p>
            <p className="text-gray-500">{course.instructor.role}</p>
          </div>
        </div>
        <div className="text-lg font-semibold text-indigo-600">
          ${course.price}
        </div>
      </div>

      <div className="mt-4 pt-4 border-t flex justify-between items-center">
        <span className={`px-2 py-1 rounded-full text-xs font-medium
          ${course.status === 'active' ? 'bg-green-100 text-green-800' :
            course.status === 'draft' ? 'bg-gray-100 text-gray-800' :
            'bg-yellow-100 text-yellow-800'}`}>
          {course.status}
        </span>
        <button className="p-2 hover:bg-gray-100 rounded-full">
          <MoreVertical className="w-5 h-5 text-gray-500" />
        </button>
      </div>
    </div>
  </div>
);

const CourseManagement = () => {
  const [view, setView] = useState('grid'); // 'grid' or 'list'
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  
  // Sample data - replace with real data
  const courses = [
    {
      id: 1,
      title: 'Advanced JavaScript Programming',
      thumbnail: null,
      enrollments: 234,
      duration: '12 weeks',
      lessons: 48,
      rating: 4.8,
      price: 99.99,
      status: 'active',
      instructor: {
        name: 'Dr. Sarah Johnson',
        role: 'Senior Instructor',
        avatar: null
      },
      category: 'Programming'
    },
    // Add more courses...
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Course Management</h1>
          <p className="text-gray-500">Manage and monitor all courses</p>
        </div>
        <button className="inline-flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors">
          <PlusCircle className="w-5 h-5" />
          Create New Course
        </button>
      </div>

      {/* Stats */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-indigo-100 rounded-lg">
              <Book className="w-6 h-6 text-indigo-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Total Courses</p>
              <p className="text-2xl font-semibold">48</p>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-green-100 rounded-lg">
              <Users className="w-6 h-6 text-green-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Total Enrollments</p>
              <p className="text-2xl font-semibold">1,234</p>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-yellow-100 rounded-lg">
              <Star className="w-6 h-6 text-yellow-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Average Rating</p>
              <p className="text-2xl font-semibold">4.8</p>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-purple-100 rounded-lg">
              <DollarSign className="w-6 h-6 text-purple-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Total Revenue</p>
              <p className="text-2xl font-semibold">$12,345</p>
            </div>
          </div>
        </div>
      </div>

      {/* Filters and Search */}
      <div className="bg-white rounded-lg shadow-sm p-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                placeholder="Search courses..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>
          <div className="flex gap-2">
            <select
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
              className="rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="all">All Categories</option>
              <option value="programming">Programming</option>
              <option value="design">Design</option>
              <option value="business">Business</option>
            </select>
            <button className="inline-flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50">
              <Filter className="w-5 h-5" />
              More Filters
            </button>
          </div>
        </div>
      </div>

      {/* Course Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {courses.map(course => (
          <CourseCard key={course.id} course={course} />
        ))}
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-between">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">1</span> to{' '}
          <span className="font-medium">10</span> of{' '}
          <span className="font-medium">48</span> courses
        </p>
        <div className="flex gap-2">
          <button className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50">
            Previous
          </button>
          <button className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseManagement;