const GA_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID; // Replace with your GA4 measurement ID

// Initialize Google Analytics
export const initializeGA = () => {
  if (typeof window === 'undefined') return;

  // Load the GA4 script
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
  script.async = true;
  document.head.appendChild(script);

  // Initialize the dataLayer
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', GA_MEASUREMENT_ID);

  // Make gtag available globally
  window.gtag = gtag;
};

// Track page views
export const trackPageView = (path) => {
  if (typeof window === 'undefined' || !window.gtag) return;

  window.gtag('event', 'page_view', {
    page_path: path,
    page_title: document.title,
  });
};

// Track events
export const trackEvent = (eventName, eventParams = {}) => {
  if (typeof window === 'undefined' || !window.gtag) return;

  window.gtag('event', eventName, eventParams);
};

// Predefined events for authentication
export const authEvents = {
  login: {
    success: (method) => trackEvent('login', {
      method: method,
      status: 'success'
    }),
    failure: (method, error) => trackEvent('login_error', {
      method: method,
      error_message: error
    })
  },
  signup: {
    success: (method) => trackEvent('sign_up', {
      method: method,
      status: 'success'
    }),
    failure: (method, error) => trackEvent('sign_up_error', {
      method: method,
      error_message: error
    })
  },
  logout: () => trackEvent('logout')
};