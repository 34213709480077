import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Loader2 } from 'lucide-react';

const VerifiedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  // Define the onboarding flow steps
  const onboardingSteps = [
    {
      path: '/create-profile',
      condition: !user?.profileComplete,
      required: true
    },
    {
      path: '/course-selection',
      condition: !user?.coursesSelected && user?.profileComplete,
      required: false
    },
    {
      path: '/payment',
      condition: !user?.paymentCompleted && user?.coursesSelected,
      required: false
    },
    {
      path: '/dashboard',
      condition: user?.profileComplete,
      required: true
    }
  ];

  // Loading state
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-indigo-100 to-purple-100">
        <div className="text-center">
          <Loader2 className="h-8 w-8 animate-spin text-indigo-600 mx-auto" />
          <p className="mt-2 text-sm text-gray-600">Checking verification status...</p>
        </div>
      </div>
    );
  }

  // Authentication check
  if (!user) {
    return (
      <Navigate 
        to="/login" 
        state={{ from: location.pathname }} 
        replace 
      />
    );
  }

  // Email verification check (except for Google users)
  const isGoogleUser = user.providerData?.[0]?.providerId === 'google.com';
  if (!user.isEmailVerified && !isGoogleUser) {
    return (
      <Navigate 
        to="/verify-email" 
        state={{ 
          email: user.email,
          from: location.pathname 
        }} 
        replace 
      />
    );
  }

  // Check onboarding flow
  for (const step of onboardingSteps) {
    // Skip if user is already on this step
    if (location.pathname === step.path) continue;

    // If condition is met and step is required, redirect
    if (step.condition && step.required) {
      return <Navigate to={step.path} replace />;
    }
  }

  // If all checks pass, render the protected component
  return children;
};

export default VerifiedRoute;