import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../config/axios';
import { mapAuthError } from './Login';
import { MailIcon, LockIcon, ChromeIcon, Loader2 } from 'lucide-react';
import { toast } from 'react-hot-toast';


const GOOGLE_CLIENT_ID = "782765837512-4f76decucjtp5i2v3g3fnjiukbk30htu.apps.googleusercontent.com";

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [googleAuth, setGoogleAuth] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let scriptLoaded = false;
    
    const loadGoogleAuth = async () => {
      try {
        const loadScript = new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = 'https://accounts.google.com/gsi/client';
          script.async = true;
          script.defer = true;
          script.onload = resolve;
          script.onerror = reject;
          document.body.appendChild(script);
        });

        await loadScript;
        scriptLoaded = true;

        if (window.google && window.google.accounts) {
          const auth = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: 'email profile',
            callback: async (response) => {
              if (response.access_token) {
                try {
                  const userResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                      Authorization: `Bearer ${response.access_token}`,
                    },
                  });
                  
                  const userData = await userResponse.json();
                  await handleGoogleUserData(userData);
                } catch (error) {
                  setError('Failed to get user information from Google');
                  setIsLoading(false);
                }
              }
            },
          });
          
          setGoogleAuth(auth);
        }
      } catch (error) {
        setError('Failed to initialize Google Sign-In. Please try again or use email signup.');
      }
    };

    loadGoogleAuth();

    return () => {
      if (scriptLoaded) {
        const script = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
        if (script) {
          script.remove();
        }
      }
    };
  }, []);

  const createUserDocument = async (user) => {
    try {
      await api.post('/api/auth/users', {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL
      });
    } catch (error) {
      
      throw new Error('Failed to create user document');
    }
  };

  const handleGoogleUserData = async (userData) => {
    try {
      const response = await api.post('/api/auth/google-signup', {
        email: userData.email,
        first_name: userData.given_name,
        last_name: userData.family_name,
        displayName: userData.name,
        photoURL: userData.picture,
        isEmailVerified: userData.email_verified,
        googleId: userData.sub
      });

      const user = response.user;
      await createUserDocument(user);

      if (response.data?.token) {
        localStorage.setItem('authToken', response.token);
        
        // User is automatically verified with Google
        toast.success('Successfully signed up with Google!');
        
        // Navigate based on profile completion
        if (response.data.user?.profileComplete) {
          navigate('/dashboard');
        } else {
          navigate('/create-profile');
        }
      }
    } catch (error) {
      setError(mapAuthError(error.response?.code || error.code));
    } finally {
      setIsLoading(false);
    }
  };

  const validatePassword = (pass) => {
    const hasMinLength = pass.length >= 8;
    const hasUpperCase = /[A-Z]/.test(pass);
    const hasLowerCase = /[a-z]/.test(pass);
    const hasNumber = /[0-9]/.test(pass);
    
    return hasMinLength && hasUpperCase && hasLowerCase && hasNumber;
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    
    if (!validatePassword(password)) {
      setError("Password must be at least 8 characters long and contain uppercase, lowercase, and numbers.");
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.post('/api/auth/signup', { email, password });

      const user = response.user;
      
      // get the user's ID token
      const idToken = await response.token;

      await api.post(
        '/api/auth/send-verification-email',
        { uid: user.uid },
        {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }
      );
      setIsVerificationSent(true);

      setEmail(user.email);

      // Create user document
      await createUserDocument(user);

      // Redirect to create profile page
      navigate('/verify-email');
    } catch (error) {
      setError(mapAuthError(error.response?.code || error.code));
      
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignUp = async () => {
    setIsLoading(true);
    setError('');

    try {
      // Initialize the Google client
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: GOOGLE_CLIENT_ID, 
        scope: 'email profile',
        callback: async (response) => {
          if (response.error) {
            if (response.error === 'popup_closed_by_user') {
              
              setIsLoading(false);
              return;
            }
            throw new Error(response.error);
          }

          try {
            // Get user info using access token
            const userInfoResponse = await fetch(
              'https://www.googleapis.com/oauth2/v3/userinfo',
              {
                headers: {
                  Authorization: `Bearer ${response.access_token}`,
                },
              }
            );

            if (!userInfoResponse.ok) {
              throw new Error('Failed to get user information');
            }

            const userData = await userInfoResponse.json();

            // Send to backend signup endpoint
            const signupResponse = await api.post('/api/auth/google-signup', {
              email: userData.email,
              name: userData.name,
              picture: userData.picture,
              googleId: userData.sub
            });

            if (signupResponse.data?.token) {
              localStorage.setItem('authToken', signupResponse.data.token);
              if (signupResponse.data.refreshToken) {
                localStorage.setItem('refreshToken', signupResponse.data.refreshToken);
              }

              toast.success('Successfully signed up with Google!');

              // Check email verification status
              if (signupResponse.data.user && !signupResponse.data.user.isEmailVerified) {
                await api.post('/api/auth/send-verification-email', { 
                  email: userData.email 
                });
                setIsVerificationSent(true);
                setEmail(userData.email);
                return;
              }

              // Navigate based on profile completion
              if (signupResponse.data.user.profileComplete) {
                navigate('/dashboard');
              } else {
                navigate('/create-profile');
              }
            }
          } catch (error) {
            
            if (error.response?.data?.code === 'auth/email-already-in-use') {
              toast.error('An account already exists with this email. Please try logging in instead.');
            } else {
              toast.error('Failed to complete Google sign-up. Please try again.');
            }
          }
        },
        error_callback: (error) => {
          
          setError('Google sign-up failed. Please try again.');
          setIsLoading(false);
        }
      });

      // Request the token
      client.requestAccessToken();
      } catch (error) {
        
        if (error.error !== 'popup_closed_by_user') {
          toast.error('Failed to initialize Google Sign-Up. Please try again.');
        }
      } finally {
        setIsLoading(false);
      }
    };

  if (isVerificationSent) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-100 to-purple-100 flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white shadow-2xl rounded-2xl p-10 text-center">
          <h2 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600">
            Verify Your Email
          </h2>
          <p className="text-gray-600 mb-4">
            We've sent a verification email to {email}. 
            Please check your inbox and click the verification link.
          </p>
          <button 
            onClick={() => window.location.reload()}
            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg text-white font-semibold bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200"
          >
            I've Verified My Email
          </button>
          <p className="text-sm text-gray-500 mt-2">
            Didn't receive the email? Check your spam folder or try resending.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 to-purple-100 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white shadow-2xl rounded-2xl p-10 transform transition-all duration-300 hover:scale-105">
        <div className="text-center">
          <h2 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600">
            Create Your Account
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Sign up to get started
          </p>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-300 text-red-700 px-4 py-3 rounded-lg text-center">
            {error}
          </div>
        )}

        <form className="space-y-6" onSubmit={handleSignup}>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MailIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input 
              type="email" 
              required 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              disabled={isLoading}
              className="pl-10 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-200 disabled:opacity-50"
            />
          </div>
          
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <LockIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input 
              type="password" 
              required 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              disabled={isLoading}
              className="pl-10 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-200 disabled:opacity-50"
            />
          </div>

          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <LockIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input 
              type="password" 
              required 
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              disabled={isLoading}
              className="pl-10 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-200 disabled:opacity-50"
            />
          </div>

          <button 
            type="submit"
            disabled={isLoading}
            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg text-white font-semibold bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 disabled:opacity-50"
          >
            {isLoading ? (
              <div className="flex items-center">
                <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                Creating Account...
              </div>
            ) : (
              'Sign Up'
            )}
          </button>
        </form>

        <div className="flex items-center justify-between mt-6">
          <div className="w-full border-t border-gray-300"></div>
          <span className="px-4 text-gray-500 text-sm">or</span>
          <div className="w-full border-t border-gray-300"></div>
        </div>

        <button 
          onClick={handleGoogleSignUp}
          disabled={isLoading || !googleAuth}
          className="w-full flex items-center justify-center py-3 px-4 border border-gray-300 rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 disabled:opacity-50"
        >
          {isLoading ? (
            <div className="flex items-center">
              <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
              Connecting to Google...
            </div>
          ) : (
            <>
              <ChromeIcon className="h-5 w-5 mr-2" />
              Sign Up with Google
            </>
          )}
        </button>

        <div className="text-center">
          <p className="mt-4 text-sm text-gray-600">
            Already have an account? 
            <button 
              onClick={() => navigate('/login')}
              className="ml-1 font-medium text-indigo-600 hover:text-indigo-500"
            >
              Sign In
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;