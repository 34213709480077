import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MailIcon, LockIcon, ChromeIcon, Loader2 } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-hot-toast';
import api from '../../config/axios';

// Rate limiting configuration
const RATE_LIMIT_ATTEMPTS = 5;
const RATE_LIMIT_WINDOW = 15 * 60 * 1000; // 15 minutes

// Google OAuth client ID
const GOOGLE_CONFIG = {
  CLIENT_ID: "782765837512-4f76decucjtp5i2v3g3fnjiukbk30htu.apps.googleusercontent.com",
  SCOPES: "email profile",
};

// Helper function to map auth errors
export const mapAuthError = (errorCode) => {
  switch (errorCode) {
    case 'auth/user-not-found':
      return 'We couldn\'t find an account associated with this email address. Double-check your email or try signing up.';
    case 'auth/wrong-password':
      return 'The password you entered is incorrect. Did you forget your password?';
    case 'auth/email-already-in-use':
      return 'This email address is already registered. Try logging in instead.';
    case 'auth/invalid-email':
      return 'Please enter a valid email address (e.g., name@example.com).';
    case 'auth/weak-password':
      return 'Please use a stronger password (min. 8 characters).';
    case 'auth/requires-recent-login':
      return 'Please log in again to continue.';
    case 'auth/user-disabled':
      return 'This account has been suspended. Contact support for help.';
    case 'auth/account-exists-with-different-credential':
      return 'An account already exists with the same email address but different login method. Try logging in with your previous authentication method.';
    case 'auth/email-not-verified':
      return 'Please verify your email address to complete registration. Check your email for a verification link or request a new one.';
    case 'auth/unauthorized-continue-uri':
      return 'Login attempt was unsuccessful due to an invalid redirect URL. Please contact support if this error persists.';
    case 'auth/invalid-verification-code':
      return 'The verification code you entered is incorrect. Please try again.';
    default:
      return 'An error occurred. Please try again.';
  }
};


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState(RATE_LIMIT_ATTEMPTS);
  const [isRateLimited, setIsRateLimited] = useState(false);
  
  const navigate = useNavigate();
  const { login, error: authError } = useAuth();

  // Password validation
  const validatePassword = (pass) => {
    if (pass.length < 8) return false;
    if (!/[A-Z]/.test(pass)) return false;
    if (!/[a-z]/.test(pass)) return false;
    if (!/[0-9]/.test(pass)) return false;
    return true;
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    
    if (isRateLimited) {
      toast.error('Too many login attempts. Please try again later.');
      return;
    }

    if (!validatePassword(password)) {
      toast.error('Password must be at least 8 characters and contain uppercase, lowercase, and numbers.');
      return;
    }

    setIsLoading(true);

    try {
      // Add better error handling for login response
      if (!email || !password) {
        throw new Error('Email and password are required');
      }

      const userStatus = await login(email, password);

      if (!userStatus) {
        throw new Error('Login failed - no response from server');
      }

      
      // Navigate based on user status
      if (!userStatus.profileComplete) {
        navigate('/create-profile');
      } else {
        navigate('/dashboard');
      }
      toast.success('Login successful!');
    } catch (error) {
      const newAttempts = remainingAttempts - 1;
      
      if (newAttempts <= 1) {
        setIsRateLimited(true);
        setTimeout(() => {
          setIsRateLimited(false);
          setRemainingAttempts(RATE_LIMIT_ATTEMPTS);
        }, RATE_LIMIT_WINDOW);
      }
      toast.error(error.message || 'Login failed. Please try again.');
      console.error('Login error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Google OAuth handler
  const handleGoogleLogin = useCallback(async () => {
    try {
      setIsLoading(true);
      
      if (!window.google?.accounts?.oauth2) {
        throw new Error('Google Sign-In not available');
      }
      
      // Check if we're on localhost and warn about potential configuration issues
      if (window.location.hostname === 'localhost' && !GOOGLE_CONFIG.CLIENT_ID.includes('localhost')) {
        console.warn('Running on localhost - ensure http://localhost:3000 is registered in Google Cloud Console');
      }

      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: GOOGLE_CONFIG.CLIENT_ID,
        scope: GOOGLE_CONFIG.SCOPES,
        callback: async (response) => {
          try {
            if (response.error) {
              throw new Error(response.error);
            }

            if (!response.access_token) {
              throw new Error('No access token received');
            }

            const userData = await fetchGoogleUserInfo(response.access_token);
            await processGoogleLogin(userData);
          } catch (error) {
            console.error('Google OAuth error:', error);
            toast.error(error.message || 'Failed to process Google login');
          } finally {
            setIsLoading(false);
          }
        },
        error_callback: (error) => {
          console.error('Google OAuth error:', error);
          toast.error('Google Sign-In failed');
          setIsLoading(false);
        }
      });

      client.requestAccessToken();
    } catch (error) {
      console.error('Google OAuth initialization error:', error);
      toast.error(error.message || 'Failed to initialize Google Sign-In');
      setIsLoading(false);
    }
  }, [navigate, toast]);

  // Fetch Google user info
  const fetchGoogleUserInfo = async (accessToken) => {
    const response = await fetch(
      'https://www.googleapis.com/oauth2/v3/userinfo',
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    return response.json();
  };

  // Process Google login with backend
  const processGoogleLogin = async (userData) => {
    const response = await api.post('/api/auth/google-signin', {
      email: userData.email,
      first_name: userData.given_name,
      last_name: userData.family_name,
      displayName: userData.name,
      photoURL: userData.picture,
      isEmailVerified: userData.email_verified,
      googleId: userData.sub
    });

    if (response.data.token) {
      localStorage.setItem('authToken', response.data.token);
      toast.success('Successfully logged in with Google!');
      navigate(response.data.user.profileComplete ? '/dashboard' : '/create-profile');
    }
  };

  // Initialize Google API
  useEffect(() => {
    const loadGoogleScript = () => {
      try {
        if (document.querySelector('script[src="https://accounts.google.com/gsi/client"]')) {
          return;
        }
        
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        
        return () => {
          try {
            const scriptElement = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
            if (scriptElement) {
              document.body.removeChild(scriptElement);
            }
          } catch (error) {
            console.error('Error removing Google script:', error);
          }
        };
      } catch (error) {
        console.error('Error loading Google script:', error);
        toast.error('Failed to load Google Sign-In');
      }
    };

    return loadGoogleScript();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 to-purple-100 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white shadow-2xl rounded-2xl p-10 transform transition-all duration-300 hover:scale-105">
        <div className="text-center">
          <h2 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600">
            Welcome Back
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Sign in to continue to your account
          </p>
        </div>

        {/* Error Messages */}
        {authError && (
          <div className="bg-red-50 border border-red-300 text-red-700 px-4 py-3 rounded-lg text-center">
            {authError}
          </div>
        )}

        {/* Login Form */}
        <form className="space-y-6" onSubmit={handleEmailLogin}>
          <div className="space-y-2">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MailIcon className="h-5 w-5 text-gray-400" />
              </div>
              <input
                id="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
                placeholder="Email address"
                className="pl-10 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-200 disabled:opacity-50"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <LockIcon className="h-5 w-5 text-gray-400" />
              </div>
              <input
                id="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
                placeholder="Password"
                className="pl-10 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-200 disabled:opacity-50"
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading || isRateLimited}
            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg text-white font-semibold bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 disabled:opacity-50"
          >
            {isLoading ? (
              <div className="flex items-center">
                <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                Signing in...
              </div>
            ) : (
              'Sign In'
            )}
          </button>
        </form>

        {/* Divider */}
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">or</span>
          </div>
        </div>

        {/* Google Sign In */}
        <button
          onClick={handleGoogleLogin}
          disabled={isLoading}
          className="w-full flex items-center justify-center py-3 px-4 border border-gray-300 rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 disabled:opacity-50"
        >
          <ChromeIcon className="h-5 w-5 mr-2" />
          Sign in with Google
        </button>

        {/* Sign Up Link */}
        <div className="text-center">
          <p className="text-sm text-gray-600">
            Don't have an account?{' '}
            <button
              onClick={() => navigate('/signup')}
              type="button"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Sign Up
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;