import React, { useState } from 'react';
import axios from 'axios';
import comingSoonImage from '../assets/image.png';
import comingSoonImageMobile from '../assets/hero-image.jpg';
import toast from 'react-hot-toast';


// Configure axios defaults
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const ComingSoon = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Add document to Firestore collection
      // Use full path, not relative
      const response = await axiosInstance.post('/api/join-waitlist', { email });
      
      // Reset form and show success message
      if (response.data && response.status === 201) {
        setEmail('');
        setSubscribed(true);
        toast.success('Thank you for joining our waitlist! You\'ll be the first to know when we launch!');
      } else { 
        setError('Failed to join waitlist');
        toast.error('Failed to join waitlist');
      }
    } catch (err) {
      // Handle different types of errors
      if (err.response) {
        // Server responded with error
        setError(err.response.data.message || 'Failed to join waitlist');
        toast.error(err.response.data.message || 'Failed to join waitlist');
      } else if (err.request) {
        // Request made but no response
        setError('Network error. Please try again.');
        toast.error('Network error. Please try again.');
      } else {
        // Something else went wrong
        setError('An error occurred. Please try again.');
        toast.error('An error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-white flex items-center justify-center px-4 py-12 pt-20 md:pt-24">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center max-w-6xl bg-white rounded-2xl shadow-2xl overflow-hidden">
        {/* Left Content Section */}
        <div className="p-6 md:p-10 space-y-6 order-2 md:order-first">
          <div className="space-y-4">
            <h1 className="text-3xl md:text-5xl font-extrabold text-gray-900 leading-tight">
              Something <span className="text-purple-600">Amazing</span> is Coming
            </h1>
            <p className="text-gray-600 text-base md:text-lg">
              We're crafting an incredible experience that will revolutionize the way you learn and build a career in tech. Sign up now to get notified when we launch!
            </p>
          </div>

          <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
            <div className="flex-grow">
              <input 
                type="email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address" 
                required
                className="w-full px-4 py-3 text-gray-900 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 focus:bg-white transition duration-300"
              />
            </div>
            {error && (
              <p className="text-red-500 text-sm">
                {error}
              </p>
            )}

            <button 
              type="submit"
              disabled={loading}
              className="w-full sm:w-auto px-6 py-3 bg-purple-600 text-white font-semibold rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-400 transition duration-300"
            >
              {loading ? 'Submitting...' : 'Join Waitlist'}
            </button>
          </form>

          {subscribed && (
            <div className="flex items-center space-x-2 text-green-600">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <p>Thank you for joining our waitlist! You'll be the first to know when we launch!</p>
            </div>
          )}
        </div>

        {/* Image Section */}
        <div className="w-full bg-purple-100/50 flex items-center justify-center p-6 md:p-12 order-1 md:order-last">
          {/* Mobile Image */}
          <img 
            src={comingSoonImageMobile} 
            alt="Launch Illustration Mobile" 
            className="block md:hidden rounded-xl shadow-xl w-full max-w-md mx-auto transform hover:scale-105 transition duration-500 ease-in-out object-cover" 
          />
          {/* Desktop Image */}
          <img 
            src={comingSoonImage} 
            alt="Launch Illustration" 
            className="hidden md:block rounded-xl shadow-xl w-full max-w-md mx-auto transform hover:scale-105 transition duration-500 ease-in-out object-cover" 
          />
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;