import React, { useState, useEffect } from 'react';
import { Book, Clock, Award, Laptop, ChevronUp, ChevronDown, User, Check, Database, Target, Globe, PlayCircle, Code, Rocket, BarChart2, Brain, Server, CloudCog } from 'lucide-react';


const CourseDetailsFAQ = ({ course }) => {
  const [activeCard, setActiveCard] = useState(null);
  const [animationKey, setAnimationKey] = useState(0);

  // Comprehensive FAQ generation with more detailed content
  const generateFAQItems = () => {
    // Base FAQs that apply to most courses
    const baseFAQs = [
      {
        title: "Course Overview",
        content: course.description,
        icon: <Book className="text-blue-500" size={24} />,
        category: "General"
      },
      {
        title: "Learning Formats",
        content: `Flexible learning options including: ${course.tags.join(', ')}. Choose the format that best fits your schedule and learning style.`,
        icon: <Clock className="text-green-500" size={24} />,
        category: "Flexibility"
      },
      {
        title: "Certification",
        content: "Upon successful completion, receive an industry-recognized certificate to boost your professional credentials.",
        icon: <Award className="text-purple-500" size={24} />,
        category: "Credentials"
      }
    ];

    // Specialized FAQ content based on course categories
    const specializedFAQMap = {
      "Full Stack Web Development": [
        {
          title: "Technical Skills Mastery", 
          content: "Comprehensive curriculum covering front-end and back-end technologies. Learn React, Node.js, Express, and MongoDB with hands-on project-based learning.", 
          icon: <Laptop className="text-indigo-500" size={24} />, 
          category: "Technical Skills"
        },
        {
          title: "Career Transformation", 
          content: "Prepare for high-demand roles like Full Stack Developer, Web Application Engineer, and Software Developer. Our program equips you with industry-relevant skills.", 
          icon: <Target className="text-red-500" size={24} />, 
          category: "Career Growth"
        }
      ],
      "Software Engineering Bootcamp": [
        {
          title: "Comprehensive Program", 
          content: "In-depth exploration of software engineering principles, object-oriented programming, system design, and practical coding techniques used in modern tech environments.", 
          icon: <Code className="text-green-500" size={24} />, 
          category: "Technical Skills"
        },
        {
          title: "Industry Readiness", 
          content: "Build a professional portfolio, master collaborative coding practices, and gain the skills needed to excel in real-world software development challenges.", 
          icon: <Rocket className="text-blue-500" size={24} />, 
          category: "Career Preparation"
        }
      ],
      "Data Analytics and Visualization": [
        {
          title: "Business Intelligence Insights", 
          content: "Transform raw data into actionable insights using advanced Excel features, Power BI visualization, and strategic data interpretation techniques.", 
          icon: <BarChart2 className="text-purple-500" size={24} />, 
          category: "Analytical Skills"
        },
        {
          title: "Practical Data Storytelling", 
          content: "Master dashboard creation, data cleaning, statistical analysis, and reporting techniques to communicate complex information effectively.", 
          icon: <CloudCog className="text-cyan-500" size={24} />, 
          category: "Practical Applications"
        }
      ],
      "AI & Machine Learning": [
        {
          title: "Advanced Techniques", 
          content: "Deep dive into cutting-edge machine learning algorithms, neural networks, and AI applications. Learn Python, TensorFlow, and practical implementation strategies.", 
          icon: <Globe className="text-teal-500" size={24} />, 
          category: "Advanced Learning"
        },
        {
          title: "Real-World Projects", 
          content: "Work on industry-relevant AI projects that demonstrate machine learning applications in healthcare, finance, robotics, and more.", 
          icon: <PlayCircle className="text-orange-500" size={24} />, 
          category: "Practical Experience"
        }
      ],
      "Data Science Bootcamp": [
        {
          title: "Comprehensive Curriculum", 
          content: "An intensive program covering data analytics, Python programming, machine learning, statistical analysis, and practical data science applications.", 
          icon: <Brain className="text-emerald-500" size={24} />, 
          category: "Advanced Learning"
        },
        {
          title: "Industry-Relevant Skills", 
          content: "Prepare for roles like Data Scientist, Data Analyst, Machine Learning Engineer with hands-on, project-based learning.", 
          icon: <Database className="text-red-500" size={24} />, 
          category: "Career Preparation"
        }
      ],
      "Frontend Development with React": [
        {
          title: "Modern Web Technologies", 
          content: "Master the React ecosystem, including advanced hooks, context management, state handling, and building responsive, interactive web applications.", 
          icon: <Server className="text-pink-500" size={24} />, 
          category: "Technical Skills"
        },
        {
          title: "UI/UX Design Principles", 
          content: "Learn to create user-friendly, visually appealing web interfaces that provide exceptional user experiences and meet modern design standards.", 
          icon: <Brain className="text-emerald-500" size={24} />, 
          category: "Design Expertise"
        }
      ],
      "Backend Development with Node.js": [
        {
          title: "Server-Side Mastery", 
          content: "Learn to build scalable backend systems using Node.js, Express, MongoDB, with focus on API design, authentication, and server optimization.", 
          icon: <CloudCog className="text-green-500" size={24} />, 
          category: "Advanced Backend Skills"
        },
        {
          title: "Cloud and Deployment", 
          content: "Understand deployment strategies, cloud services, and how to take applications from local development to production environments.", 
          icon: <Rocket className="text-blue-500" size={24} />, 
          category: "Infrastructure Knowledge"
        }
      ]
    };

    // Combine base and specialized FAQs
    const specializedFAQs = specializedFAQMap[course.title] || [];
    return [...baseFAQs, ...specializedFAQs];
  };

  const faqItems = generateFAQItems();

  const toggleCard = (index) => {
    setActiveCard(activeCard === index ? null : index);
    setAnimationKey(prev => prev + 1);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br py-16 px-4">
      <div className="max-w-4xl mx-auto">
        {/* Course Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-4">
            {course.title}
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Your comprehensive guide to mastering essential skills and advancing your career.
          </p>
        </div>

        {/* FAQ Sections */}
        <div className="space-y-6">
          {faqItems.map((item, index) => (
            <div 
              key={`${index}-${animationKey}`}
              className={`
                border-2 rounded-2xl shadow-lg transition-all duration-300 ease-in-out overflow-hidden
                ${activeCard === index 
                  ? 'border-blue-500 bg-white' 
                  : 'border-gray-200 hover:border-blue-300 bg-gray-50'}
              `}
            >
              {/* FAQ Header */}
              <div 
                onClick={() => toggleCard(index)}
                className="flex items-center justify-between p-6 cursor-pointer group"
              >
                <div className="flex items-center space-x-6">
                  {item.icon}
                  <div>
                    <h3 className={`
                      text-xl font-bold transition-colors duration-300
                      ${activeCard === index ? 'text-blue-600' : 'text-gray-800 group-hover:text-blue-500'}
                    `}>
                      {item.title}
                    </h3>
                    <p className="text-sm text-gray-500 mt-1">{item.category}</p>
                  </div>
                </div>

                {/* Expand/Collapse Icon */}
                {activeCard === index ? (
                  <ChevronUp 
                    className="text-blue-600 transition-transform" 
                    size={28} 
                  />
                ) : (
                  <ChevronDown 
                    className="text-gray-500 group-hover:text-blue-500 transition-transform" 
                    size={28} 
                  />
                )}
              </div>

              {/* FAQ Content */}
              {activeCard === index && (
                <div 
                  className={`
                    px-6 pb-6 text-gray-700 
                    animate-fade-in-down
                  `}
                >
                  <div className="border-t pt-4 mt-4">
                    {item.content}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Additional Course Information */}
        <div className="mt-12 bg-white rounded-2xl shadow-lg p-8">
          <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
            Course Details
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="flex items-center space-x-4">
              <User className="text-blue-500" size={32} />
              <div>
                <h4 className="font-semibold text-gray-700">Skill Level</h4>
                <p className="text-gray-600">{course.skillLevel || 'Intermediate'}</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Clock className="text-green-500" size={32} />
              <div>
                <h4 className="font-semibold text-gray-700">Duration</h4>
                <p className="text-gray-600">{course.duration || '8 Weeks'}</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Check className="text-purple-500" size={32} />
              <div>
                <h4 className="font-semibold text-gray-700">Learning Outcomes</h4>
                <ul className="list-disc list-inside text-gray-600">
                  {course.learningOutcomes?.map((outcome, idx) => (
                    <li key={idx}>{outcome}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Award className="text-yellow-500" size={32} />
              <div>
                <h4 className="font-semibold text-gray-700">Certification</h4>
                <p className="text-gray-600">
                  Industry-recognized certificate upon course completion
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetailsFAQ;