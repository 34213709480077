import React, { useState } from 'react';
import { data, useNavigate } from 'react-router-dom';
import api from '../../../config/axios';
import { 
  Upload, Plus, Minus, Clock, DollarSign, 
  Save, Eye, CheckCircle, Image as ImageIcon 
} from 'lucide-react';
import { toast } from 'react-hot-toast';

const CreateCourse = () => {
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState({
    title: '',
    description: '',
    shortDescription: '',
    category: '',
    level: 'beginner',
    price: '',
    thumbnail: null,
    duration: '',
    prerequisites: [''],
    learningObjectives: [''],
    sections: [
      {
        title: '',
        description: '',
        lessons: [
          {
            title: '',
            type: 'video',
            duration: '',
            content: '',
            isPreview: false
          }
        ]
      }
    ],
    status: 'draft', // draft, published, archived
    instructor: '', // This should be populated from available instructors
    enrollmentLimit: '',
    startDate: '',
    language: 'english'
  });

  const [currentSection, setCurrentSection] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);

  // Handle thumbnail upload
  const handleThumbnailUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // You'll want to handle actual file upload to your storage
      setCourseData(prev => ({
        ...prev,
        thumbnail: URL.createObjectURL(file)
      }));
    }
  };

  // Add/Remove dynamic fields
  const handleAddField = (field, index, sectionIndex = null) => {
    setCourseData(prev => {
      const updated = { ...prev };
      if (sectionIndex !== null) {
        // Add lesson to section
        updated.sections[sectionIndex].lessons.push({
          title: '',
          type: 'video',
          duration: '',
          content: '',
          isPreview: false
        });
      } else if (field === 'section') {
        // Add new section
        updated.sections.push({
          title: '',
          description: '',
          lessons: []
        });
      } else {
        // Add prerequisite or learning objective
        updated[field].push('');
      }
      return updated;
    });
  };

  const handleRemoveField = (field, index, sectionIndex = null) => {
    setCourseData(prev => {
      const updated = { ...prev };
      if (sectionIndex !== null) {
        // Remove lesson from section
        updated.sections[sectionIndex].lessons.splice(index, 1);
      } else if (field === 'section') {
        // Remove section
        updated.sections.splice(index, 1);
      } else {
        // Remove prerequisite or learning objective
        updated[field].splice(index, 1);
      }
      return updated;
    });
  };

  const handlePublish = async () => {
    setIsSubmitting(true);
    try {
      // Validate required fields
      if (!courseData.title || !courseData.description || !courseData.price) {
        throw new Error('Please fill in all required fields');
      }

      // Update status to published
      const dataToSubmit = {
        ...courseData,
        status: 'published',
        publishedAt: new Date().toISOString()
      };

      // API call to save course
      const response = await api.post('/api/courses', dataToSubmit, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Failed to create course');
      }

      toast.success('Course published successfully!');
      navigate('/admin/courses');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-5xl mx-auto pb-12">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Create New Course</h1>
          <p className="text-gray-500">Fill in the details to create a new course</p>
        </div>
        <div className="flex gap-3">
          <button
            onClick={() => setPreviewMode(!previewMode)}
            className="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            <Eye className="w-5 h-5" />
            Preview
          </button>
          <button
            onClick={handlePublish}
            disabled={isSubmitting}
            className="flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700"
          >
            {isSubmitting ? (
              <>
                <span className="animate-spin">...</span>
                Publishing
              </>
            ) : (
              <>
                <CheckCircle className="w-5 h-5" />
                Publish Course
              </>
            )}
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6 space-y-8">
        {/* Basic Information */}
        <section>
          <h2 className="text-lg font-semibold mb-4">Basic Information</h2>
          <div className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Course Title *
              </label>
              <input
                type="text"
                value={courseData.title}
                onChange={(e) => setCourseData(prev => ({
                  ...prev,
                  title: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="e.g., Advanced Web Development"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Short Description *
              </label>
              <input
                type="text"
                value={courseData.shortDescription}
                onChange={(e) => setCourseData(prev => ({
                  ...prev,
                  shortDescription: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Brief overview of the course"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Detailed Description *
              </label>
              <textarea
                value={courseData.description}
                onChange={(e) => setCourseData(prev => ({
                  ...prev,
                  description: e.target.value
                }))}
                rows={4}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Detailed description of what students will learn"
              />
            </div>
          </div>
        </section>

        {/* Course Details */}
        <section>
          <h2 className="text-lg font-semibold mb-4">Course Details</h2>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Category *
              </label>
              <select
                value={courseData.category}
                onChange={(e) => setCourseData(prev => ({
                  ...prev,
                  category: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="">Select Category</option>
                <option value="web-development">Web Development</option>
                <option value="mobile-development">Mobile Development</option>
                <option value="data-science">Data Science</option>
                <option value="design">Design</option>
                <option value="business">Business</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Level
              </label>
              <select
                value={courseData.level}
                onChange={(e) => setCourseData(prev => ({
                  ...prev,
                  level: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Price (USD) *
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DollarSign className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  value={courseData.price}
                  onChange={(e) => setCourseData(prev => ({
                    ...prev,
                    price: e.target.value
                  }))}
                  className="pl-10 w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="99.99"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Duration
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Clock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={courseData.duration}
                  onChange={(e) => setCourseData(prev => ({
                    ...prev,
                    duration: e.target.value
                  }))}
                  className="pl-10 w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="e.g., 6 weeks"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Course Thumbnail */}
        <section>
          <h2 className="text-lg font-semibold mb-4">Course Thumbnail</h2>
          <div className="flex items-center justify-center border-2 border-dashed border-gray-300 rounded-lg p-6">
            {courseData.thumbnail ? (
              <div className="relative">
                <img
                  src={courseData.thumbnail}
                  alt="Course thumbnail"
                  className="max-w-md rounded-lg"
                />
                <button
                  onClick={() => setCourseData(prev => ({ ...prev, thumbnail: null }))}
                  className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full hover:bg-red-700"
                >
                  <Minus className="w-4 h-4" />
                </button>
              </div>
            ) : (
              <div className="text-center">
                <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
                <div className="mt-4 flex text-sm text-gray-600">
                  <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500">
                    <span>Upload a file</span>
                    <input
                      type="file"
                      className="sr-only"
                      accept="image/*"
                      onChange={handleThumbnailUpload}
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, GIF up to 10MB
                </p>
              </div>
            )}
          </div>
        </section>

        {/* Prerequisites & Learning Objectives */}
        <section>
          <h2 className="text-lg font-semibold mb-4">Prerequisites & Learning Objectives</h2>
          
          <div className="space-y-6">
            {/* Prerequisites */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Prerequisites
              </label>
              {courseData.prerequisites.map((prerequisite, index) => (
                <div key={index} className="flex gap-2 mb-2">
                  <input
                    type="text"
                    value={prerequisite}
                    onChange={(e) => {
                      const newPrerequisites = [...courseData.prerequisites];
                      newPrerequisites[index] = e.target.value;
                      setCourseData(prev => ({
                        ...prev,
                        prerequisites: newPrerequisites
                      }));
                    }}
                    className="flex-1 rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="e.g., Build a full-stack web application"
                  />
                  <button
                    onClick={() => handleRemoveField('learningObjectives', index)}
                    className="p-2 text-red-600 hover:text-red-700"
                  >
                    <Minus className="w-5 h-5" />
                  </button>
                </div>
              ))}
              <button
                onClick={() => handleAddField('learningObjectives')}
                className="flex items-center gap-1 text-sm text-indigo-600 hover:text-indigo-700"
              >
                <Plus className="w-4 h-4" />
                Add Learning Objective
              </button>
            </div>
          </div>
        </section>

        {/* Course Content */}
        <section>
          <h2 className="text-lg font-semibold mb-4">Course Content</h2>
          
          {courseData.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-8 bg-gray-50 rounded-lg p-6">
              <div className="mb-4">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-md font-medium">Section {sectionIndex + 1}</h3>
                  <button
                    onClick={() => handleRemoveField('section', sectionIndex)}
                    className="p-2 text-red-600 hover:text-red-700"
                  >
                    <Minus className="w-5 h-5" />
                  </button>
                </div>

                <input
                  type="text"
                  value={section.title}
                  onChange={(e) => {
                    const newSections = [...courseData.sections];
                    newSections[sectionIndex].title = e.target.value;
                    setCourseData(prev => ({
                      ...prev,
                      sections: newSections
                    }));
                  }}
                  className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 mb-2"
                  placeholder="Section Title"
                />

                <textarea
                  value={section.description}
                  onChange={(e) => {
                    const newSections = [...courseData.sections];
                    newSections[sectionIndex].description = e.target.value;
                    setCourseData(prev => ({
                      ...prev,
                      sections: newSections
                    }));
                  }}
                  className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="Section Description"
                  rows={2}
                />
              </div>

              {/* Lessons */}
              <div className="space-y-4 ml-6">
                {section.lessons.map((lesson, lessonIndex) => (
                  <div key={lessonIndex} className="bg-white rounded-lg p-4">
                    <div className="flex justify-between items-start gap-4">
                      <div className="flex-1 space-y-4">
                        <input
                          type="text"
                          value={lesson.title}
                          onChange={(e) => {
                            const newSections = [...courseData.sections];
                            newSections[sectionIndex].lessons[lessonIndex].title = e.target.value;
                            setCourseData(prev => ({
                              ...prev,
                              sections: newSections
                            }));
                          }}
                          className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          placeholder="Lesson Title"
                        />

                        <div className="grid grid-cols-3 gap-4">
                          <select
                            value={lesson.type}
                            onChange={(e) => {
                              const newSections = [...courseData.sections];
                              newSections[sectionIndex].lessons[lessonIndex].type = e.target.value;
                              setCourseData(prev => ({
                                ...prev,
                                sections: newSections
                              }));
                            }}
                            className="rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          >
                            <option value="video">Video</option>
                            <option value="quiz">Quiz</option>
                            <option value="assignment">Assignment</option>
                            <option value="resource">Resource</option>
                          </select>

                          <input
                            type="text"
                            value={lesson.duration}
                            onChange={(e) => {
                              const newSections = [...courseData.sections];
                              newSections[sectionIndex].lessons[lessonIndex].duration = e.target.value;
                              setCourseData(prev => ({
                                ...prev,
                                sections: newSections
                              }));
                            }}
                            className="rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder="Duration (e.g., 15:00)"
                          />

                          <div className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              checked={lesson.isPreview}
                              onChange={(e) => {
                                const newSections = [...courseData.sections];
                                newSections[sectionIndex].lessons[lessonIndex].isPreview = e.target.checked;
                                setCourseData(prev => ({
                                  ...prev,
                                  sections: newSections
                                }));
                              }}
                              className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <span className="text-sm text-gray-600">Preview</span>
                          </div>
                        </div>

                        {lesson.type === 'video' && (
                          <div className="flex items-center gap-4">
                            <input
                              type="file"
                              accept="video/*"
                              onChange={(e) => {
                                // Handle video upload
                              }}
                              className="hidden"
                              id={`video-${sectionIndex}-${lessonIndex}`}
                            />
                            <label
                              htmlFor={`video-${sectionIndex}-${lessonIndex}`}
                              className="flex items-center gap-2 px-4 py-2 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200"
                            >
                              <Upload className="w-5 h-5" />
                              Upload Video
                            </label>
                            <span className="text-sm text-gray-500">
                              {lesson.content ? 'Video uploaded' : 'No video uploaded'}
                            </span>
                          </div>
                        )}

                        {(lesson.type === 'quiz' || lesson.type === 'assignment') && (
                          <textarea
                            value={lesson.content}
                            onChange={(e) => {
                              const newSections = [...courseData.sections];
                              newSections[sectionIndex].lessons[lessonIndex].content = e.target.value;
                              setCourseData(prev => ({
                                ...prev,
                                sections: newSections
                              }));
                            }}
                            className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            placeholder={`Enter ${lesson.type} content...`}
                            rows={3}
                          />
                        )}
                      </div>

                      <button
                        onClick={() => handleRemoveField('lesson', lessonIndex, sectionIndex)}
                        className="p-2 text-red-600 hover:text-red-700"
                      >
                        <Minus className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                ))}

                <button
                  onClick={() => handleAddField('lesson', null, sectionIndex)}
                  className="flex items-center gap-1 text-sm text-indigo-600 hover:text-indigo-700"
                >
                  <Plus className="w-4 h-4" />
                  Add Lesson
                </button>
              </div>
            </div>
          ))}

          <button
            onClick={() => handleAddField('section')}
            className="flex items-center gap-2 px-4 py-2 border-2 border-dashed border-gray-300 rounded-lg hover:border-gray-400 w-full justify-center"
          >
            <Plus className="w-5 h-5" />
            Add New Section
          </button>
        </section>

        {/* Pricing and Enrollment */}
        <section>
          <h2 className="text-lg font-semibold mb-4">Enrollment Settings</h2>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Enrollment Limit
              </label>
              <input
                type="number"
                value={courseData.enrollmentLimit}
                onChange={(e) => setCourseData(prev => ({
                  ...prev,
                  enrollmentLimit: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Leave empty for unlimited"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={courseData.startDate}
                onChange={(e) => setCourseData(prev => ({
                  ...prev,
                  startDate: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>
        </section>

        {/* Save Buttons */}
        <div className="flex justify-end gap-4 pt-6 border-t">
          <button
            onClick={() => navigate('/admin/courses')}
            className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={() => handlePublish()}
            disabled={isSubmitting}
            className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
          >
            <Save className="w-5 h-5" />
            {isSubmitting ? 'Saving...' : 'Save & Publish'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateCourse;