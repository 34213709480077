import React from 'react';
import { Link } from 'react-router-dom';

const JoinUsToday = () => {
  return (
    <section class="bg-gray-50 dark:bg-gray-800">
        <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
            <div class="max-w-screen-sm mx-auto text-center">
                <h2 class="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">Your Tech Journey Starts Here</h2>
                  <p class="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
                      Whether you’re taking your first step into the world of tech or looking to elevate your career, Swiss Tech Academy is your partner in unlocking limitless opportunities. It’s time to shape your future, build your skills, and secure your spot in the tech industry.
                </p>
                <Link to="/" class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">Join Us Today</Link>
            </div>
        </div>
    </section>
  );
};

export default JoinUsToday;