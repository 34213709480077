import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const ProtectedRoute = ({ 
  children, 
  requiredStatus,
  requiredRoles,
  fallbackPath = '/login'
}) => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Check user status when component mounts or user/loading state changes
    if (!loading && !user) {
      navigate('/login', { 
        state: { 
          from: window.location.pathname,
          message: 'Please log in to access this page' 
        }
      });
    }
  }, [user, loading, navigate]);

  // Show loading state
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600" />
      </div>
    );
  }

  // If no user, don't render anything (useEffect will handle redirect)
  if (!user) {
    return null;
  }

  // Check required status if specified
  if (requiredStatus) {
    switch (requiredStatus) {
      case 'profile-created':
        if (!user.profileComplete) {
          return <Navigate to="/create-profile" replace />;
        }
        break;
      case 'courses-selected':
        if (!user.coursesSelected) {
          return <Navigate to="/course-selection" replace />;
        }
        break;
      case 'payment-completed':
        if (!user.paymentCompleted) {
          return <Navigate to="/payment" replace />;
        }
        break;
      default:
        break;
    }
  }

  // check required role if specified
  if (requiredRoles && !requiredRoles.includes(user.role)) {
    return <Navigate to={fallbackPath} replace />;
  }

  // If all checks pass, render the protected component
  return children;
};

export default ProtectedRoute;