import { Routes, Route, Navigate } from 'react-router-dom';
import AdminLayout from './AdminLayout';
import AdminDashboard from './AdminDashboard';
import UserManagement from './UserManagement';
import CourseManagement from './CourseManagement';
import Analytics from './Analytics';
import Communications from './Communications';
import Settings from './Settings';
import CreateCourse from './CreateCourse';


const AdminRoutes = () => {
  return (
    <AdminLayout>
    <Routes>
        <Route path="dashboard" element={<AdminDashboard />} />
        
        {/* User Management Routes */}
        <Route path="users" element={<UserManagement />} />
        <Route path="users/students" element={<UserManagement filter="student" />} />
        <Route path="users/instructors" element={<UserManagement filter="instructor" />} />
        <Route path="users/admins" element={<UserManagement filter="admin" />} />
        
        {/* Course Management Routes */}
        <Route path="courses" element={<CourseManagement />} />
        <Route path="courses/categories" element={<CourseManagement view="categories" />} />
        <Route path="courses/materials" element={<CourseManagement view="materials" />} />
        <Route path="courses/create" element={<CreateCourse />} />
        
        {/* Analytics Routes */}
        <Route path="analytics" element={<Analytics />} />
        <Route path="analytics/enrollments" element={<Analytics view="enrollments" />} />
        <Route path="analytics/revenue" element={<Analytics view="revenue" />} />
        
        {/* Communications Routes */}
        <Route path="communications/announcements" element={<Communications view="announcements" />} />
        <Route path="communications/email-templates" element={<Communications view="email-templates" />} />
        <Route path="communications/tickets" element={<Communications view="tickets" />} />
        
        {/* Settings Route */}
        <Route path="settings" element={<Settings />} />
        
        {/* Redirect to dashboard if no route matches */}
        <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
    </Routes>
    </AdminLayout>
  );
};

export default AdminRoutes;