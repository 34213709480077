import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import api from "../config/axios";


// Create the Context
const DashboardContext = createContext();

// Create a Provider Component
export const DashboardProvider = ({ children }) => {
  const [userStats, setUserStats] = useState({
    coursesEnrolled: 0,
    completedCourses: 0,
    pendingPayments: 0,
  });

  const [recentActivity, setRecentActivity] = useState([]);
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchDashboardData = async () => {
    try {
      setIsLoading(true);

      // Fetch user profile
      const profileResponse = await api.get('/api/dashboard/profile');
      const userData = profileResponse.data;
      setUserName(`${userData.firstName} ${userData.lastName}`);

      // Fetch user stats
      const statsResponse = await api.get('/api/dashboard/stats');
      const statsData = statsResponse.data;

      setUserStats({
        coursesEnrolled: statsData.coursesEnrolled,
        completedCourses: statsData.completedCourses,
        pendingPayments: statsData.pendingPayments,
      });

      // Fetch recent activity
      const activityResponse = await api.get('/api/dashboard/recent-activity');
      const activityData = activityResponse.data;

      setRecentActivity(activityData);

    } catch (error) {
      setError('Failed to fetch dashboard data');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <DashboardContext.Provider value={{ userStats, recentActivity, userName, isLoading, error }}>
      {!isLoading && children}
    </DashboardContext.Provider>
  );
};

// Custom hook to use the DashboardContext
export const useDashboard = () => {
  return useContext(DashboardContext);
};