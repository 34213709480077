import React, { useState, useEffect } from 'react';
import { Sparkles, Loader2 } from 'lucide-react';
import api from '../../config/axios';

const CourseRecommendations = ({ onSelectCourse }) => {
  const [recommendations, setRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchRecommendations();
  }, []);

  const fetchRecommendations = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/courses/recommendations', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setRecommendations(response.data.recommendations);
    } catch (error) {
      throw new Error('Failed to fetch course recommendations');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="animate-spin h-6 w-6 text-blue-600" />
      </div>
    );
  }

  if (recommendations.length === 0) {
    return null;
  }

  return (
    <div className="bg-gradient-to-r from-indigo-50 to-purple-50 rounded-xl p-6 mb-8">
      <div className="flex items-center mb-4">
        <Sparkles className="text-indigo-600 mr-2" />
        <h2 className="text-xl font-bold text-gray-800">
          Recommended for You
        </h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {recommendations.map(course => (
          <div 
            key={course.id}
            className="bg-white rounded-lg shadow-sm p-4 hover:shadow-md transition-shadow duration-200"
          >
            <h3 className="font-semibold text-gray-800 mb-2">
              {course.title}
            </h3>
            <p className="text-sm text-gray-600 mb-3">
              {course.description}
            </p>
            <div className="flex items-center justify-between">
              <span className="text-sm text-indigo-600">
                {course.matchReason}
              </span>
              <button
                onClick={() => onSelectCourse(course)}
                className="px-3 py-1 text-sm text-indigo-600 border border-indigo-600 rounded-full hover:bg-indigo-50"
              >
                Add Course
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseRecommendations;