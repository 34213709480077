import React, { useState } from 'react';
import { 
  Mail, MessageSquare, Bell, Search, Filter, 
  PlusCircle, Edit, Trash2, Send, FileText,
  Calendar, Users, ChevronDown
} from 'lucide-react';

const TabButton = ({ active, icon: Icon, label, count, onClick }) => (
  <button
    onClick={onClick}
    className={`flex items-center gap-2 px-4 py-2 rounded-lg text-sm
      ${active 
        ? 'bg-indigo-50 text-indigo-600' 
        : 'text-gray-600 hover:bg-gray-50'}`}
  >
    <Icon className="w-5 h-5" />
    <span>{label}</span>
    {count && (
      <span className={`px-2 py-0.5 rounded-full text-xs
        ${active 
          ? 'bg-indigo-100 text-indigo-600' 
          : 'bg-gray-100 text-gray-600'}`}>
        {count}
      </span>
    )}
  </button>
);

const AnnouncementCard = ({ announcement }) => (
  <div className="bg-white rounded-lg shadow-sm p-6">
    <div className="flex items-start justify-between">
      <div className="flex items-start gap-4">
        <div className={`p-2 rounded-lg ${
          announcement.type === 'general' ? 'bg-blue-50 text-blue-600' :
          announcement.type === 'course' ? 'bg-green-50 text-green-600' :
          'bg-purple-50 text-purple-600'
        }`}>
          {announcement.type === 'general' ? <Bell className="w-5 h-5" /> :
           announcement.type === 'course' ? <FileText className="w-5 h-5" /> :
           <Calendar className="w-5 h-5" />}
        </div>
        <div>
          <h3 className="font-semibold text-gray-900">{announcement.title}</h3>
          <p className="text-sm text-gray-500 mt-1">{announcement.content}</p>
          <div className="flex items-center gap-4 mt-4">
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <Users className="w-4 h-4" />
              {announcement.audience}
            </div>
            <div className="text-sm text-gray-500">
              {new Date(announcement.scheduledFor).toLocaleDateString()}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button className="p-2 hover:bg-gray-50 rounded-lg">
          <Edit className="w-5 h-5 text-gray-500" />
        </button>
        <button className="p-2 hover:bg-gray-50 rounded-lg">
          <Trash2 className="w-5 h-5 text-gray-500" />
        </button>
      </div>
    </div>
  </div>
);

const EmailTemplateCard = ({ template }) => (
  <div className="bg-white rounded-lg shadow-sm p-6">
    <div className="flex items-start justify-between">
      <div className="flex items-start gap-4">
        <div className="p-2 bg-indigo-50 text-indigo-600 rounded-lg">
          <Mail className="w-5 h-5" />
        </div>
        <div>
          <h3 className="font-semibold text-gray-900">{template.name}</h3>
          <p className="text-sm text-gray-500 mt-1">{template.description}</p>
          <div className="flex items-center gap-4 mt-4">
            <span className={`px-2 py-1 rounded-full text-xs font-medium
              ${template.type === 'welcome' ? 'bg-green-100 text-green-800' :
                template.type === 'notification' ? 'bg-blue-100 text-blue-800' :
                'bg-purple-100 text-purple-800'}`}>
              {template.type}
            </span>
            <span className="text-sm text-gray-500">
              Last modified: {new Date(template.lastModified).toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <button className="p-2 hover:bg-gray-50 rounded-lg">
          <Edit className="w-5 h-5 text-gray-500" />
        </button>
        <button className="px-3 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700">
          Use Template
        </button>
      </div>
    </div>
  </div>
);

const Communications = () => {
  const [activeTab, setActiveTab] = useState('announcements');
  const [searchTerm, setSearchTerm] = useState('');

  // Sample data - replace with real data
  const announcements = [
    {
      id: 1,
      title: 'New Course Release',
      content: 'We are excited to announce the launch of our Advanced React Development course!',
      type: 'course',
      audience: 'All Students',
      scheduledFor: '2024-01-20'
    },
    {
      id: 2,
      title: 'Platform Maintenance',
      content: 'Scheduled maintenance will be performed on January 25th from 2 AM to 4 AM UTC.',
      type: 'general',
      audience: 'All Users',
      scheduledFor: '2024-01-24'
    }
  ];

  const emailTemplates = [
    {
      id: 1,
      name: 'Welcome Email',
      description: 'Sent to new users upon registration',
      type: 'welcome',
      lastModified: '2024-01-15'
    },
    {
      id: 2,
      name: 'Course Completion',
      description: 'Congratulates users on completing a course',
      type: 'notification',
      lastModified: '2024-01-10'
    }
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Communications</h1>
          <p className="text-gray-500">Manage announcements and email templates</p>
        </div>
        <button className="inline-flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors">
          <PlusCircle className="w-5 h-5" />
          {activeTab === 'announcements' ? 'New Announcement' : 'New Template'}
        </button>
      </div>

      {/* Tabs */}
      <div className="flex items-center gap-4 border-b pb-4">
        <TabButton
          active={activeTab === 'announcements'}
          icon={Bell}
          label="Announcements"
          count={announcements.length}
          onClick={() => setActiveTab('announcements')}
        />
        <TabButton
          active={activeTab === 'email'}
          icon={Mail}
          label="Email Templates"
          count={emailTemplates.length}
          onClick={() => setActiveTab('email')}
        />
      </div>

      {/* Search and Filters */}
      <div className="bg-white rounded-lg shadow-sm p-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <input
                type="text"
                placeholder={`Search ${activeTab}...`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>
          <div className="flex gap-2">
            <select className="rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500">
              <option value="all">All Types</option>
              <option value="general">General</option>
              <option value="course">Course</option>
              <option value="event">Event</option>
            </select>
            <button className="inline-flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50">
              <Filter className="w-5 h-5" />
              More Filters
            </button>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="space-y-4">
        {activeTab === 'announcements' ? (
          announcements.map(announcement => (
            <AnnouncementCard key={announcement.id} announcement={announcement} />
          ))
        ) : (
          emailTemplates.map(template => (
            <EmailTemplateCard key={template.id} template={template} />
          ))
        )}
      </div>
    </div>
  );
};

export default Communications;