import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Book, 
  Bell, 
  Clock, 
  Award,
  BarChart2,
  Loader2
} from 'lucide-react';
import api from '../../config/axios';
import { toast, Toaster } from 'react-hot-toast';
import Analytics from './Analytics';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const navigate = useNavigate();

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        navigate('/login');
        return;
      }

      const response = await api.get('/api/auth/dashboard', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setDashboardData(response);
    } catch (error) {
      toast.error('Failed to load dashboard data');
    } finally {
      setIsLoading(false);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const token = localStorage.getItem('authToken');
      await api.put(
        `/api/auth/notifications/${notificationId}/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // Update local state to mark notification as read
      setDashboardData(prev => ({
        ...prev,
        notifications: prev.notifications.map(notif =>
          notif.id === notificationId ? { ...notif, read: true } : notif
        )
      }));
    } catch (error) {
      toast.error('Failed to mark notification as read');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="animate-spin h-8 w-8 text-blue-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Toaster position="top-right" />
      
      {/* Header */}
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold text-gray-900">
              Welcome back, {dashboardData?.user?.firstName}!
            </h1>
            <div className="relative">
              <button className="p-2 rounded-full hover:bg-gray-100">
                <Bell className="h-6 w-6 text-gray-600" />
                {dashboardData?.notifications?.some(n => !n.read) && (
                  <span className="absolute top-0 right-0 h-3 w-3 bg-red-500 rounded-full" />
                )}
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        {/* Stats Overview */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <StatsCard
            title="Enrolled Courses"
            value={dashboardData?.enrolledCourses?.length || 0}
            icon={Book}
            color="blue"
          />
          <StatsCard
            title="Total Progress"
            value={`${calculateOverallProgress(dashboardData?.enrolledCourses)}%`}
            icon={BarChart2}
            color="green"
          />
          <StatsCard
            title="Learning Hours"
            value={calculateTotalHours(dashboardData?.enrolledCourses)}
            icon={Clock}
            color="purple"
          />
          <StatsCard
            title="Achievements"
            value={calculateAchievements(dashboardData?.enrolledCourses)}
            icon={Award}
            color="yellow"
          />
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-200 mb-8">
          <nav className="-mb-px flex space-x-8">
            <TabButton
              active={activeTab === 'overview'}
              onClick={() => setActiveTab('overview')}
              label="Overview"
            />
            <TabButton
              active={activeTab === 'courses'}
              onClick={() => setActiveTab('courses')}
              label="My Courses"
            />
            <TabButton
              active={activeTab === 'activity'}
              onClick={() => setActiveTab('activity')}
              label="Recent Activity"
            />
            <TabButton
              active={activeTab === 'analytics'}
              onClick={() => setActiveTab('analytics')}
              label="Analytics"
            />
            {activeTab === 'analytics' && <Analytics />}
          </nav>
        </div>

        {/* Tab Content */}
        {activeTab === 'overview' && (
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Course Progress */}
            <div className="lg:col-span-2 bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold mb-4">Course Progress</h2>
              {dashboardData?.enrolledCourses?.map(course => (
                <CourseProgressCard key={course.id} course={course} />
              ))}
            </div>

            {/* Notifications */}
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold mb-4">Notifications</h2>
              <div className="space-y-4">
                {dashboardData?.notifications?.map(notification => (
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                    onMarkAsRead={markNotificationAsRead}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {activeTab === 'courses' && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {dashboardData?.enrolledCourses?.map(course => (
              <CourseCard key={course.id} course={course} />
            ))}
          </div>
        )}

        {activeTab === 'activity' && (
          <div className="bg-white rounded-lg shadow">
            <div className="flow-root">
              <ul className="divide-y divide-gray-200">
                {dashboardData?.recentActivity?.map(activity => (
                  <ActivityItem key={activity.id} activity={activity} />
                ))}
              </ul>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

// Helper Components
const StatsCard = ({ title, value, icon: Icon, color }) => (
  <div className="bg-white rounded-lg shadow p-6">
    <div className="flex items-center">
      <div className={`p-3 rounded-full bg-${color}-100`}>
        <Icon className={`h-6 w-6 text-${color}-600`} />
      </div>
      <div className="ml-4">
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <p className="text-2xl font-semibold text-gray-900">{value}</p>
      </div>
    </div>
  </div>
);

const TabButton = ({ active, onClick, label }) => (
  <button
    onClick={onClick}
    className={`
      pb-4 px-1 border-b-2 font-medium text-sm
      ${active
        ? 'border-blue-500 text-blue-600'
        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
    `}
  >
    {label}
  </button>
);

const CourseProgressCard = ({ course }) => (
  <div className="mb-4 last:mb-0">
    <div className="flex justify-between items-center mb-2">
      <h3 className="font-medium">{course.title}</h3>
      <span className="text-sm text-gray-500">{course.progress}%</span>
    </div>
    <div className="w-full bg-gray-200 rounded-full h-2">
      <div
        className="bg-blue-600 rounded-full h-2"
        style={{ width: `${course.progress}%` }}
      />
    </div>
  </div>
);

const NotificationCard = ({ notification, onMarkAsRead }) => (
  <div className={`p-4 rounded-lg ${notification.read ? 'bg-gray-50' : 'bg-blue-50'}`}>
    <div className="flex justify-between">
      <p className="text-sm font-medium">{notification.title}</p>
      {!notification.read && (
        <button
          onClick={() => onMarkAsRead(notification.id)}
          className="text-xs text-blue-600 hover:text-blue-800"
        >
          Mark as read
        </button>
      )}
    </div>
    <p className="text-sm text-gray-600 mt-1">{notification.message}</p>
    <p className="text-xs text-gray-500 mt-2">
      {new Date(notification.timestamp).toLocaleDateString()}
    </p>
  </div>
);

const CourseCard = ({ course }) => (
  <div className="bg-white rounded-lg shadow overflow-hidden">
    <div className="p-6">
      <h3 className="text-lg font-medium">{course.title}</h3>
      <p className="text-sm text-gray-600 mt-2">{course.description}</p>
      <div className="mt-4">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm text-gray-500">Progress</span>
          <span className="text-sm font-medium">{course.progress}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-blue-600 rounded-full h-2"
            style={{ width: `${course.progress}%` }}
          />
        </div>
      </div>
    </div>
  </div>
);

const ActivityItem = ({ activity }) => (
  <li className="py-4 px-6">
    <div className="flex space-x-3">
      <div className="flex-1">
        <p className="text-sm text-gray-900">{activity.description}</p>
        <p className="text-xs text-gray-500 mt-1">
          {new Date(activity.timestamp).toLocaleString()}
        </p>
      </div>
    </div>
  </li>
);

// Helper functions
const calculateOverallProgress = (courses) => {
  if (!courses?.length) return 0;
  const totalProgress = courses.reduce((sum, course) => sum + course.progress, 0);
  return Math.round(totalProgress / courses.length);
};

const calculateTotalHours = (courses) => {
  if (!courses?.length) return 0;
  return courses.reduce((sum, course) => sum + (course.timeSpent || 0), 0);
};

const calculateAchievements = (courses) => {
  if (!courses?.length) return 0;
  return courses.filter(course => course.progress === 100).length;
};

export default Dashboard;