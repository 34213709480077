import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import api from '../../config/axios';
import { 
  BookOpen, 
  Check, 
  X, 
  ShoppingCart, 
  Filter, 
  Search,
  Loader2
} from 'lucide-react';
import { toast, Toaster } from 'react-hot-toast';
import CourseRecommendations from './CourseRecommendations';


// Move these to a separate config file in a real app
const COURSE_CATEGORIES = [
  'Programming',
  'Design',
  'Business',
  'Data Science',
  'Marketing'
];

const CourseSelection = () => {
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const navigate = useNavigate();
  const { user, updateUserStatus } = useAuth();

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await api.get('/api/courses', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setAvailableCourses(response.data.courses);
    } catch (error) {
      toast.error('Failed to load courses. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleCourseSelection = (course) => {
    setSelectedCourses(prev => 
      prev.includes(course.id) 
        ? prev.filter(id => id !== course.id)
        : [...prev, course.id]
    );
  };

  const handleCourseSelection = async () => {
    if (selectedCourses.length === 0) {
      toast.error('Please select at least one course');
      return;
    }

    setIsSubmitting(true);
    try {
      const token = localStorage.getItem('authToken');
      await api.post('/api/auth/select-courses', 
        { courseIds: selectedCourses },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // Update user status in context
      await updateUserStatus({ coursesSelected: true });
      
      toast.success('Courses selected successfully!');
      navigate('/payment');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to save course selection');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Filter courses based on search and category
  const filteredCourses = availableCourses.filter(course => 
    (selectedCategory === 'All' || course.category === selectedCategory) &&
    course.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-blue-100">
        <Loader2 className="animate-spin h-8 w-8 text-blue-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 p-8 pt-24">
      <Toaster position="top-right" reverseOrder={false} />
      
      <div className="max-w-7xl mx-auto">
        <div className="mb-8 text-center">
          <h1 className="text-4xl font-extrabold text-gray-800 mb-4">
            Select Your Courses
          </h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Choose the courses that align with your career goals
          </p>
        </div>

        {/* Course Recommendations */}
        <CourseRecommendations />

        {/* Search and Filter Section */}
        <div className="mb-8 flex flex-col md:flex-row gap-4">
          <div className="relative flex-grow">
            <input 
              type="text"
              placeholder="Search courses..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>

          <div className="relative">
            <select 
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none"
            >
              <option value="All">All Categories</option>
              {COURSE_CATEGORIES.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
            <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
        </div>

        {/* Courses Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCourses.map(course => (
            <CourseCard 
              key={course.id}
              course={course}
              isSelected={selectedCourses.includes(course.id)}
              onToggle={() => toggleCourseSelection(course)}
            />
          ))}
        </div>

        {/* Selected Courses Summary */}
        {selectedCourses.length > 0 && (
          <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-bold mb-4">Selected Courses:</h3>
            <ul className="space-y-2">
              {selectedCourses.map(courseId => {
                const course = availableCourses.find(c => c.id === courseId);
                return (
                  <li key={courseId} className="flex justify-between items-center">
                    <span>{course.title}</span>
                    <span>${course.price}</span>
                  </li>
                );
              })}
              <li className="flex justify-between items-center pt-4 border-t font-bold">
                <span>Total:</span>
                <span>
                  ${selectedCourses.reduce((total, courseId) => {
                    const course = availableCourses.find(c => c.id === courseId);
                    return total + course.price;
                  }, 0)}
                </span>
              </li>
            </ul>
          </div>
        )}

        {/* Proceed Button */}
        <div className="mt-8 text-center">
          <button 
            onClick={handleCourseSelection}
            disabled={isSubmitting || selectedCourses.length === 0}
            className={`
              px-8 py-4 rounded-lg text-white font-bold flex items-center justify-center mx-auto space-x-2
              transition duration-200
              ${selectedCourses.length > 0 && !isSubmitting
                ? 'bg-blue-600 hover:bg-blue-700' 
                : 'bg-gray-400 cursor-not-allowed'}
            `}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                Processing...
              </>
            ) : (
              <>
                <ShoppingCart size={24} />
                <span>
                  Proceed to Payment ({selectedCourses.length} Course{selectedCourses.length !== 1 ? 's' : ''})
                </span>
              </>
            )}
          </button>
        </div>

      </div>
    </div>
  );
};

// Extracted Course Card Component
const CourseCard = ({ course, isSelected, onToggle }) => (
  <div 
    className={`
      border rounded-2xl p-6 transition-all duration-300 
      ${isSelected 
        ? 'bg-blue-50 border-blue-500 scale-105' 
        : 'bg-white border-gray-200 hover:shadow-lg'}
    `}
  >
    <div className="flex justify-between items-center mb-4">
      <h3 className="text-xl font-bold text-gray-800">{course.title}</h3>
      <BookOpen 
        className={isSelected ? 'text-blue-600' : 'text-gray-400'} 
        size={24} 
      />
    </div>
    
    <p className="text-gray-600 mb-4">{course.description}</p>
    
    <div className="flex justify-between items-center mb-4">
      <div className="flex space-x-2">
        <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs">
          {course.difficulty}
        </span>
        <span className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs">
          {course.duration}
        </span>
      </div>
      <div className="font-bold text-gray-800">${course.price}</div>
    </div>

    <button 
      onClick={onToggle}
      className={`
        w-full py-3 rounded-lg transition-all duration-300 flex items-center justify-center
        ${isSelected
          ? 'bg-red-50 text-red-600 hover:bg-red-100' 
          : 'bg-blue-50 text-blue-600 hover:bg-blue-100'}
      `}
    >
      {isSelected ? (
        <>
          <X className="mr-2" size={20} /> Remove
        </>
      ) : (
        <>
          <Check className="mr-2" size={20} /> Select
        </>
      )}
    </button>
  </div>
);

export default CourseSelection;