import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig' // Adjust the import path as needed
import api from '../config/axios';


export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Check auth state on mount and token change
  useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = async () => {
    const token = localStorage.getItem('authToken');
    
    if (!token) {
      setUser(null);
      setLoading(false);
      return;
    }

    try {
      const response = await api.get('/api/auth/profile', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setUser(response.user);
    } catch (error) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password) => {
    try {
      setLoading(true);
      setError(null);

      const response = await api.post('/api/auth/login', { email, password });

      if (!response || !response.token) {
        throw new Error('No authentication token received');
      }

      // Store tokens
      localStorage.setItem('authToken', response.token);

      if (response.refreshToken) {
        localStorage.setItem('refreshToken', response.refreshToken);
      }

      // Get user profile
      const profileResponse = await api.get('/api/auth/profile', {
        headers: {
          Authorization: `Bearer ${response.token}`
        }
      });

      setUser(profileResponse.user);

      // Return user status for navigation
      return {
        profileComplete: profileResponse.user.profileComplete,
        coursesSelected: profileResponse.user.coursesSelected,
        paymentCompleted: profileResponse.user.paymentCompleted
      };
    } catch (error) {
      setError(error.message || 'Login failed');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signup = async (email, password) => {
    try {
      setLoading(true);
      setError(null);

      const response = await api.post('/api/auth/signup', { email, password });
      
      // Don't set user yet as email needs verification
      return response;
    } catch (error) {
      setError(error.response?.message || 'Signup failed');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (token) {
        // Optional: Notify backend about logout
        await api.post('/api/auth/logout', {}, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
    } catch (error) {
      setError('Failed to logout');
    } finally {
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      setUser(null);
    }
  };

  const updateUserStatus = async (status) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await api.post('/api/auth/update-status', status, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUser(prev => ({
        ...prev,
        ...status
      }));
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const sendVerificationEmail = async (token) => {
    try {
      setLoading(true);
      setError(null);

      const response = await api.post('/api/auth/send-verification-email', { token });
      return response;
    } catch (error) {
      setError(error.response?.message || 'Email verification failed');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const resendVerificationEmail = async (email) => {
    try {
      setLoading(true);
      setError(null);

      const response = await api.post('/api/auth/resend-verification-email', { email });
      return response;
    } catch (error) {
      setError(error.response?.message || 'Failed to resend verification email');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    loading,
    error,
    login,
    signup,
    logout,
    updateUserStatus,
    sendVerificationEmail,
    resendVerificationEmail,
    checkAuthState
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for protected routes
export const useRequireAuth = (redirectTo = '/login') => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !user) {
      navigate(redirectTo);
    }
  }, [user, loading, navigate, redirectTo]);

  return { user, loading };
};

// Custom hook for public routes
export const PublicRoute = ({ children }) => {
  const { user, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading && user) {
      if (user.emailVerified || user.providerData?.[0]?.providerId === 'google.com') {
        // If user is verified or is a Google user, redirect to dashboard
        navigate('/dashboard', { replace: true });
      } else if (!location.pathname.includes('/verify-email')) {
        // If user is not verified, redirect to verification page
        navigate('/verify-email', { replace: true });
      }
    }
  }, [user, loading, navigate, location.pathname]);

  if (loading) return <div>Loading...</div>;

  return children;
};