// AdminLayout.jsx
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Users, BookOpen, BarChart3, Settings, Mail, 
  LogOut, Menu, X, ChevronDown, Home 
} from 'lucide-react';

const Sidebar = ({ isMobileMenuOpen, toggleMobileMenu }) => {
  const [openMenus, setOpenMenus] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    {
      title: 'Dashboard',
      icon: <Home className="w-5 h-5" />,
      path: '/admin/dashboard'
    },
    {
      title: 'User Management',
      icon: <Users className="w-5 h-5" />,
      submenu: [
        { title: 'All Users', path: '/admin/users' },
        { title: 'Students', path: '/admin/users/students' },
        { title: 'Instructors', path: '/admin/users/instructors' },
        { title: 'Admins', path: '/admin/users/admins' }
      ]
    },
    {
      title: 'Course Management',
      icon: <BookOpen className="w-5 h-5" />,
      submenu: [
        { title: 'All Courses', path: '/admin/courses' },
        { title: 'Categories', path: '/admin/courses/categories' },
        { title: 'Materials', path: '/admin/courses/materials' },
        { title: 'Create Course', path: '/admin/courses/create' }
      ]
    },
    {
      title: 'Analytics',
      icon: <BarChart3 className="w-5 h-5" />,
      submenu: [
        { title: 'Overview', path: '/admin/analytics' },
        { title: 'Enrollments', path: '/admin/analytics/enrollments' },
        { title: 'Revenue', path: '/admin/analytics/revenue' }
      ]
    },
    {
      title: 'Communications',
      icon: <Mail className="w-5 h-5" />,
      submenu: [
        { title: 'Announcements', path: '/admin/communications/announcements' },
        { title: 'Email Templates', path: '/admin/communications/email-templates' },
        { title: 'Support Tickets', path: '/admin/communications/tickets' }
      ]
    },
    {
      title: 'Settings',
      icon: <Settings className="w-5 h-5" />,
      path: '/admin/settings'
    }
  ];

  const toggleSubmenu = (title) => {
    setOpenMenus(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobileMenuOpen) toggleMobileMenu();
  };

  const renderMenuItem = (item) => {
    const isActive = location.pathname === item.path;
    const hasSubmenu = item.submenu && item.submenu.length > 0;
    const isSubmenuOpen = openMenus[item.title];

    return (
      <div key={item.title} className="w-full">
        <button
          onClick={() => hasSubmenu ? toggleSubmenu(item.title) : handleNavigation(item.path)}
          className={`w-full flex items-center justify-between p-2 rounded-lg text-sm
            ${isActive ? 'bg-indigo-100 text-indigo-600' : 'text-gray-600 hover:bg-gray-100'}`}
        >
          <div className="flex items-center gap-3">
            {item.icon}
            <span>{item.title}</span>
          </div>
          {hasSubmenu && (
            <ChevronDown className={`w-4 h-4 transition-transform ${isSubmenuOpen ? 'rotate-180' : ''}`} />
          )}
        </button>

        {hasSubmenu && isSubmenuOpen && (
          <div className="ml-6 mt-2 space-y-2">
            {item.submenu.map(subItem => (
              <button
                key={subItem.title}
                onClick={() => handleNavigation(subItem.path)}
                className={`w-full text-left p-2 text-sm rounded-lg
                  ${location.pathname === subItem.path 
                    ? 'bg-indigo-50 text-indigo-600' 
                    : 'text-gray-600 hover:bg-gray-50'}`}
              >
                {subItem.title}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };

  const sidebarClasses = `
    ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
    fixed inset-y-0 left-0 z-50 w-64 bg-white border-r border-gray-200 pb-10
    overflow-y-auto transition-transform duration-300 ease-in-out
    lg:translate-x-0 lg:static lg:h-screen
  `;

  return (
    <aside className={sidebarClasses}>
      <div className="p-4 border-b">
        <h1 className="text-xl font-bold text-indigo-600">Admin Dashboard</h1>
      </div>
      
      <nav className="p-4 space-y-2">
        {menuItems.map(renderMenuItem)}
      </nav>

      <div className="absolute bottom-0 left-0 right-0 p-4 border-t bg-white">
        <button 
          onClick={() => {/* Handle logout */}}
          className="w-full flex items-center gap-2 p-2 text-red-600 hover:bg-red-50 rounded-lg"
        >
          <LogOut className="w-5 h-5" />
          <span>Logout</span>
        </button>
      </div>
    </aside>
  );
};

const AdminLayout = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(prev => !prev);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile menu button */}
      <button
        onClick={toggleMobileMenu}
        className="lg:hidden fixed top-4 left-4 z-50 p-2 rounded-lg bg-white shadow-lg"
      >
        {isMobileMenuOpen ? (
          <X className="w-6 h-6 text-gray-600" />
        ) : (
          <Menu className="w-6 h-6 text-gray-600" />
        )}
      </button>

      {/* Backdrop */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={toggleMobileMenu}
        />
      )}

      <div className="flex">
        <Sidebar 
          isMobileMenuOpen={isMobileMenuOpen} 
          toggleMobileMenu={toggleMobileMenu} 
        />
        
        <main className="flex-1 p-8 lg:ml-64">
          <div className="max-w-7xl mx-auto">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;