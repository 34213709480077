import React, { useState } from 'react';
import { 
  LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, 
  Tooltip, Legend, ResponsiveContainer, AreaChart, Area 
} from 'recharts';
import { 
  TrendingUp, Users, DollarSign, GraduationCap,
  Calendar, ArrowUp, ArrowDown, Download
} from 'lucide-react';

const StatCard = ({ title, value, trend, percentage, icon: Icon }) => (
  <div className="bg-white rounded-lg shadow-sm p-6">
    <div className="flex justify-between">
      <div>
        <p className="text-sm text-gray-500">{title}</p>
        <h3 className="text-2xl font-semibold mt-1">{value}</h3>
        <div className="flex items-center gap-1 mt-2">
          {trend === 'up' ? (
            <ArrowUp className="w-4 h-4 text-green-500" />
          ) : (
            <ArrowDown className="w-4 h-4 text-red-500" />
          )}
          <span className={trend === 'up' ? 'text-green-500' : 'text-red-500'}>
            {percentage}%
          </span>
          <span className="text-gray-500 text-sm">vs last month</span>
        </div>
      </div>
      <div className="p-3 bg-indigo-50 rounded-full h-fit">
        <Icon className="w-6 h-6 text-indigo-600" />
      </div>
    </div>
  </div>
);

const AnalyticsChart = ({ title, chart: Chart, data, config }) => (
  <div className="bg-white rounded-lg shadow-sm p-6">
    <div className="flex justify-between items-center mb-6">
      <h3 className="text-lg font-semibold">{title}</h3>
      <button className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900">
        <Download className="w-4 h-4" />
        Download Report
      </button>
    </div>
    <div className="h-80">
      <ResponsiveContainer width="100%" height="100%">
        {Chart}
      </ResponsiveContainer>
    </div>
  </div>
);

const Analytics = () => {
  const [dateRange, setDateRange] = useState('30d');

  // Sample data - replace with real data
  const revenueData = Array.from({ length: 30 }, (_, i) => ({
    date: `2024-01-${i + 1}`,
    revenue: Math.floor(Math.random() * 5000) + 1000,
    students: Math.floor(Math.random() * 100) + 20
  }));

  const courseData = [
    { name: 'Web Development', students: 845, completion: 78 },
    { name: 'Data Science', students: 645, completion: 82 },
    { name: 'Mobile Dev', students: 445, completion: 71 },
    { name: 'UI/UX Design', students: 345, completion: 85 },
    { name: 'Cloud Computing', students: 245, completion: 69 }
  ];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Analytics Overview</h1>
          <p className="text-gray-500">Monitor your platform's performance</p>
        </div>
        <div className="flex gap-2">
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="7d">Last 7 days</option>
            <option value="30d">Last 30 days</option>
            <option value="90d">Last 90 days</option>
            <option value="1y">Last year</option>
          </select>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          title="Total Revenue"
          value="$48,250"
          trend="up"
          percentage="12.5"
          icon={DollarSign}
        />
        <StatCard
          title="Active Students"
          value="2,845"
          trend="up"
          percentage="8.2"
          icon={Users}
        />
        <StatCard
          title="Course Completion"
          value="76.5%"
          trend="down"
          percentage="3.1"
          icon={GraduationCap}
        />
        <StatCard
          title="New Enrollments"
          value="425"
          trend="up"
          percentage="15.3"
          icon={TrendingUp}
        />
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <AnalyticsChart
          title="Revenue & Enrollment Trends"
          chart={
            <LineChart data={revenueData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                tickFormatter={(date) => new Date(date).toLocaleDateString()} 
              />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="revenue"
                stroke="#4F46E5"
                strokeWidth={2}
                dot={false}
                name="Revenue ($)"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="students"
                stroke="#059669"
                strokeWidth={2}
                dot={false}
                name="New Students"
              />
            </LineChart>
          }
        />

        <AnalyticsChart
          title="Course Performance"
          chart={
            <BarChart data={courseData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="students" fill="#4F46E5" name="Total Students" />
              <Bar dataKey="completion" fill="#059669" name="Completion Rate (%)" />
            </BarChart>
          }
        />

        <AnalyticsChart
          title="Student Engagement"
          chart={
            <AreaChart data={revenueData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="date" 
                tickFormatter={(date) => new Date(date).toLocaleDateString()} 
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="students"
                stackId="1"
                stroke="#4F46E5"
                fill="#4F46E5"
                fillOpacity={0.2}
                name="Active Students"
              />
            </AreaChart>
          }
        />

        {/* Additional Analysis */}
        <div className="bg-white rounded-lg shadow-sm p-6 space-y-6">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">Top Performing Courses</h3>
            <button className="text-sm text-indigo-600 hover:text-indigo-800">
              View All
            </button>
          </div>
          
          <div className="space-y-4">
            {courseData.map((course, index) => (
              <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center gap-4">
                  <span className="text-lg font-semibold text-gray-400">
                    #{index + 1}
                  </span>
                  <div>
                    <p className="font-medium">{course.name}</p>
                    <p className="text-sm text-gray-500">
                      {course.students} students enrolled
                    </p>
                  </div>
                </div>
                <div className="text-right">
                  <p className="font-semibold text-green-600">
                    {course.completion}%
                  </p>
                  <p className="text-sm text-gray-500">completion</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;