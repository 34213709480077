import React, { useState } from 'react';
import { 
  Settings as SettingsIcon, User, Globe, Shield, 
  Mail, CreditCard, Bell, Database, Save,
  CheckCircle, AlertCircle
} from 'lucide-react';

const SettingsSection = ({ title, description, children }) => (
  <div className="border rounded-lg overflow-hidden">
    <div className="px-6 py-4 bg-gray-50 border-b">
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
      <p className="text-sm text-gray-500 mt-1">{description}</p>
    </div>
    <div className="p-6 bg-white space-y-6">
      {children}
    </div>
  </div>
);

const SettingRow = ({ label, description, children }) => (
  <div className="flex flex-col sm:flex-row sm:items-start gap-4 py-4">
    <div className="sm:w-1/3">
      <label className="text-sm font-medium text-gray-900">{label}</label>
      {description && (
        <p className="text-sm text-gray-500 mt-1">{description}</p>
      )}
    </div>
    <div className="sm:w-2/3">
      {children}
    </div>
  </div>
);

const Settings = () => {
  const [generalSettings, setGeneralSettings] = useState({
    siteName: 'Swiss Tech Academy',
    siteUrl: 'https://swiss-tech-academy.com',
    supportEmail: 'support@swiss-tech-academy.com',
    timezone: 'UTC+1',
    language: 'en',
    maintenanceMode: false
  });

  const [emailSettings, setEmailSettings] = useState({
    smtpHost: 'smtp.example.com',
    smtpPort: '587',
    smtpUsername: 'notifications@example.com',
    smtpEncryption: 'tls',
    senderName: 'Swiss Tech Academy',
    senderEmail: 'no-reply@swiss-tech-academy.com'
  });

  const [paymentSettings, setPaymentSettings] = useState({
    currency: 'USD',
    stripeEnabled: true,
    paypalEnabled: true,
    testMode: true
  });

  const handleSave = () => {
    // Save settings logic here
  };

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">System Settings</h1>
          <p className="text-gray-500">Manage your platform configurations</p>
        </div>
        <button 
          onClick={handleSave}
          className="inline-flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
        >
          <Save className="w-5 h-5" />
          Save Changes
        </button>
      </div>

      <div className="grid grid-cols-12 gap-6">
        {/* Navigation Sidebar */}
        <div className="col-span-12 lg:col-span-3">
          <nav className="space-y-1">
            {[
              { icon: Globe, label: 'General' },
              { icon: Mail, label: 'Email' },
              { icon: Bell, label: 'Notifications' },
              { icon: CreditCard, label: 'Payment' },
              { icon: Shield, label: 'Security' },
              { icon: Database, label: 'Backup' }
            ].map(({ icon: Icon, label }) => (
              <button
                key={label}
                className="flex items-center gap-3 w-full p-3 text-left text-sm font-medium rounded-lg
                  hover:bg-gray-50"
              >
                <Icon className="w-5 h-5 text-gray-500" />
                {label}
              </button>
            ))}
          </nav>
        </div>

        {/* Settings Content */}
        <div className="col-span-12 lg:col-span-9 space-y-6">
          {/* General Settings */}
          <SettingsSection
            title="General Settings"
            description="Configure basic platform settings"
          >
            <SettingRow 
              label="Site Name" 
              description="The name of your platform"
            >
              <input
                type="text"
                value={generalSettings.siteName}
                onChange={(e) => setGeneralSettings(prev => ({
                  ...prev,
                  siteUrl: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </SettingRow>

            <SettingRow
              label="Support Email"
              description="Primary contact email for support"
            >
              <input
                type="email"
                value={generalSettings.supportEmail}
                onChange={(e) => setGeneralSettings(prev => ({
                  ...prev,
                  supportEmail: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </SettingRow>

            <SettingRow
              label="Maintenance Mode"
              description="Enable to show maintenance page to users"
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={generalSettings.maintenanceMode}
                  onChange={(e) => setGeneralSettings(prev => ({
                    ...prev,
                    maintenanceMode: e.target.checked
                  }))}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="ml-2 text-sm text-gray-500">
                  {generalSettings.maintenanceMode ? 'Enabled' : 'Disabled'}
                </span>
              </div>
            </SettingRow>
          </SettingsSection>

          {/* Email Settings */}
          <SettingsSection
            title="Email Configuration"
            description="Configure email sending settings"
          >
            <SettingRow
              label="SMTP Host"
              description="Your email server hostname"
            >
              <input
                type="text"
                value={emailSettings.smtpHost}
                onChange={(e) => setEmailSettings(prev => ({
                  ...prev,
                  smtpHost: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </SettingRow>

            <SettingRow
              label="SMTP Port"
              description="Server port number"
            >
              <input
                type="text"
                value={emailSettings.smtpPort}
                onChange={(e) => setEmailSettings(prev => ({
                  ...prev,
                  smtpPort: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </SettingRow>

            <SettingRow
              label="Encryption"
              description="Choose encryption method"
            >
              <select
                value={emailSettings.smtpEncryption}
                onChange={(e) => setEmailSettings(prev => ({
                  ...prev,
                  smtpEncryption: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="tls">TLS</option>
                <option value="ssl">SSL</option>
                <option value="none">None</option>
              </select>
            </SettingRow>

            <SettingRow
              label="Test Email"
              description="Send a test email to verify settings"
            >
              <button className="inline-flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50">
                <Mail className="w-5 h-5" />
                Send Test Email
              </button>
            </SettingRow>
          </SettingsSection>

          {/* Payment Settings */}
          <SettingsSection
            title="Payment Configuration"
            description="Configure payment gateway settings"
          >
            <SettingRow
              label="Default Currency"
              description="Select default currency for payments"
            >
              <select
                value={paymentSettings.currency}
                onChange={(e) => setPaymentSettings(prev => ({
                  ...prev,
                  currency: e.target.value
                }))}
                className="w-full rounded-lg border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <option value="USD">USD - US Dollar</option>
                <option value="EUR">EUR - Euro</option>
                <option value="GBP">GBP - British Pound</option>
                <option value="CHF">CHF - Swiss Franc</option>
              </select>
            </SettingRow>

            <SettingRow
              label="Payment Gateways"
              description="Enable/disable payment methods"
            >
              <div className="space-y-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={paymentSettings.stripeEnabled}
                    onChange={(e) => setPaymentSettings(prev => ({
                      ...prev,
                      stripeEnabled: e.target.checked
                    }))}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="ml-2 text-sm text-gray-900">Stripe</span>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={paymentSettings.paypalEnabled}
                    onChange={(e) => setPaymentSettings(prev => ({
                      ...prev,
                      paypalEnabled: e.target.checked
                    }))}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <span className="ml-2 text-sm text-gray-900">PayPal</span>
                </div>
              </div>
            </SettingRow>

            <SettingRow
              label="Test Mode"
              description="Enable test mode for development"
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={paymentSettings.testMode}
                  onChange={(e) => setPaymentSettings(prev => ({
                    ...prev,
                    testMode: e.target.checked
                  }))}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <span className="ml-2 text-sm text-gray-500">
                  {paymentSettings.testMode ? 'Test Mode Active' : 'Live Mode'}
                </span>
              </div>
            </SettingRow>
          </SettingsSection>
        </div>
      </div>
    </div>
  );
};

export default Settings;