import React, { useState, useEffect, useRef } from 'react';
import { Pause, Play } from 'lucide-react';

const ContinuousCardCarousel = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const containerRef = useRef(null);
  
  const cards = [
    {
      id: 1,
      image: "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=400&h=300&q=80",
      title: "Learning Together", 
      description: "Collaborative coding workshops"
    },
    {
      id: 2,
      image: "https://images.unsplash.com/photo-1497366216548-37526070297c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=400&h=300&q=80",
      title: "Modern Workspace",
      description: "State-of-the-art facilities"
    },
    {
      id: 3,
      image: "https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=400&h=300&q=80",
      title: "Hands-on Experience",
      description: "Real-world projects"
    },
    {
      id: 4,
      image: "https://images.unsplash.com/photo-1558222218-b7b54eede3f3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=400&h=300&q=80",
      title: "Expert Guidance",
      description: "Learn from professionals"
    },
    {
      id: 5,
      image: "https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=400&h=300&q=80",
      title: "Innovation Hub",
      description: "Creative technology space"
    }
  ];

  // Double the cards to create seamless loop
  const duplicatedCards = [...cards, ...cards];

  useEffect(() => {
    if (isPaused || isHovered) return;

    const container = containerRef.current;
    let animationFrameId;
    let startTime;
    const duration = 30000; // Time to scroll through all cards once

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = ((timestamp - startTime) % duration) / duration;
      
      if (container) {
        const totalWidth = container.scrollWidth / 2;
        container.scrollLeft = progress * totalWidth;
        
        // Reset scroll position when we reach the duplicate set
        if (container.scrollLeft >= totalWidth) {
          startTime = timestamp;
          container.scrollLeft = 0;
        }
      }
      
      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [isPaused, isHovered]);

  return (
    <div className="relative w-full max-w-6xl mx-auto bg-gray-900 rounded-xl p-8 mt-10 overflow-hidden">
      <div className="absolute top-4 right-4 z-10">
        <button
          onClick={() => setIsPaused(!isPaused)}
          className="bg-white/10 hover:bg-white/20 text-white p-2 rounded-full backdrop-blur-sm transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-white/50"
          aria-label={isPaused ? "Resume animation" : "Pause animation"}
        >
          {isPaused ? (
            <Play className="w-4 h-4" />
          ) : (
            <Pause className="w-4 h-4" />
          )}
        </button>
      </div>

      <div
        ref={containerRef}
        className="flex gap-6 overflow-x-hidden"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {duplicatedCards.map((card, index) => (
          <div
            key={`${card.id}-${index}`}
            className="flex-none w-72 group"
          >
            <div className="relative h-full bg-gray-800 rounded-xl overflow-hidden shadow-lg transform transition-transform duration-300 group-hover:scale-105">
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-gray-900/90" />
              
              <div className="absolute bottom-0 left-0 right-0 p-6">
                <h3 className="text-white text-xl font-semibold mb-2">
                  {card.title}
                </h3>
                <p className="text-gray-300 text-sm">
                  {card.description}
                </p>
              </div>

              <div className="absolute inset-0 bg-gray-900/30 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContinuousCardCarousel;