import { useParams, useNavigate } from 'react-router-dom';
import { programs } from './Programs';
import CourseDetailsFAQ from './CourseDetailsFAQ';
import Curriculum from './Curriculum';


const ProgramDetails = () => {
    const { programTitle } = useParams();
    const navigate = useNavigate();


    // Decode the URL parameter and find the program
    const decodedProgramTitle = decodeURIComponent(programTitle);
    const program = Object.values(programs).flat()
        .find(p => p.title === decodedProgramTitle);

    if (!program) {
        return (
            <div className="p-6 max-w-screen-lg mx-auto">
                <p>No program details found.</p>
                <button 
                    onClick={() => navigate('/programs')}
                    className="mt-4 px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded hover:bg-purple-700"
                >
                    Back to Programs
                </button>
            </div>
        );
    }

    const curriculumTopics = program.curriculumTopics || [];

    return (
        <div className="p-6 max-w-screen-lg mx-auto pt-24 md:pt-24">
            <button
                onClick={() => navigate('/programs')}
                className="mt-4 mb-4 px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded hover:bg-purple-700"
            >
                Back to Programs
            </button>
            
            <div className="grid md:grid-cols-2 gap-8">
                <div>
                    <h1 className="text-4xl font-extrabold mb-4 text-gray-900 dark:text-white">
                        {program.title}
                    </h1>
                    <p className="text-gray-700 dark:text-gray-300 mb-6">
                        {program.description}
                    </p>
                    <div className="space-y-2">
                        {program.tags.map((tag, idx) => (
                            <span
                                key={idx}
                                className="inline-block px-4 py-2 mr-5 text-sm font-medium text-white bg-purple-600 rounded-full"
                            >
                                {tag}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="relative">
                    <img
                        src={program.img}
                        alt={program.title}
                        className="w-full h-full object-cover rounded-lg shadow-lg"
                    />
                </div>
            </div>
            
            <div className="mt-6 text-gray-700 dark:text-gray-300">
                <h2 className="text-2xl font-bold mb-4">Program Details</h2>
                <p>{program.details}</p>
            </div>

            <CourseDetailsFAQ course={program} />

            {curriculumTopics.length > 0 && (
                <Curriculum topics={curriculumTopics} />
            )}
            
        </div>
    );
};

export default ProgramDetails;