import { Link } from "react-router-dom";
import logo from '../assets/new-logo-no-bg.png';


const Footer = () => {
  return (
    <footer class="bg-white dark:bg-gray-800">
        <div class="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div class="text-center">
                <Link to="/" class="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-whiteLink">
                    <img src={logo} class="w-50 h-40 mr-3 sm:h-40" alt="Swiss Tech Academy Logo" />
                    
                  </Link>
                  <h2 class="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-whiteLink">
                      Follow us on Social Media
                  </h2>
                  <p>info@swisstechacademy.com</p>
                  <ul class="flex justify-center mt-5 space-x-5">
                      {/* facebook */}
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=61570577215338" class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400Link">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
                        </a>
                    </li>
                    {/* instagram */}
                    <li>
                        <a href="https://www.instagram.com/swisstech.academy/?utm_source=ig_web_button_share_sheet" class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400Link">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" /></svg>
                        </a>
                    </li>
                    {/* LinkedIn */}
                    <li>
                        <a href="https://www.linkedin.com/company/swiss-tech-academy/" class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fill-rule="evenodd" d="M19 0H5C2.238 0 0 2.238 0 5v14c0 2.762 2.238 5 5 5h14c2.762 0 5-2.238 5-5V5c0-2.762-2.238-5-5-5zM7.617 19H4.42V9.807h3.197V19zM6.018 8.285h-.021c-1.067 0-1.759-.733-1.759-1.648 0-.933.71-1.648 1.799-1.648s1.759.715 1.78 1.648c0 .915-.691 1.648-1.799 1.648zM19 19h-3.197v-4.74c0-1.19-.427-2.004-1.496-2.004-.816 0-1.3.548-1.514 1.078-.078.185-.099.444-.099.703V19h-3.198s.042-7.775 0-8.593h3.197v1.216c.423-.65 1.18-1.576 2.871-1.576 2.097 0 3.667 1.382 3.667 4.352V19z" clip-rule="evenodd" />
                            </svg>
                        </a>
                    </li>

                  </ul>
                  <span class="block pt-6 text-sm text-center text-gray-500 dark:text-gray-400">
                      © Swiss Tech Academy™. All Rights Reserved
                  </span>
            </div>
        </div>
    </footer>
  );
}

export default Footer;