import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { MailIcon, RefreshCw, ArrowRight, Loader2 } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-hot-toast';
import api from '../../config/axios';

const VerifyEmail = () => {
  const [isResending, setIsResending] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [error, setError] = useState('');
  
  const { resendVerificationEmail, checkAuthState, updateUserStatus } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  
  const email = location.state?.email;
  const from = location.state?.from || '/dashboard';

  useEffect(() => {
    const verifyEmailWithCode = async () => {
      const oobCode = searchParams.get('oobCode');
      
      if (oobCode) {
        setVerifying(true);
        try {
          // Call the backend to verify the email
          await api.post('/api/auth/verify-email', { oobCode });
          
          // Update local auth state
          const user = await checkAuthState();
          if (user) {
            await updateUserStatus({ isEmailVerified: true });
          }
          
          toast.success('Email verified successfully!');
          navigate(from);
        } catch (error) {
          setError(error.message || 'Verification failed');
          toast.error('Email verification failed. Please try again.');
        } finally {
          setVerifying(false);
        }
      }
    };

    verifyEmailWithCode();
  }, [searchParams, navigate, from, checkAuthState, updateUserStatus]);

  const handleResendVerification = async () => {
    if (!email) {
      toast.error('No email address found. Please try logging in again.');
      navigate('/login');
      return;
    }

    try {
      setIsResending(true);
      await resendVerificationEmail(email);
      toast.success('Verification email sent successfully!');
    } catch (error) {
      toast.error('Failed to resend verification email. Please try again.');
    } finally {
      setIsResending(false);
    }
  };

  const handleVerificationCheck = async () => {
    try {
      setVerifying(true);
      const user = await checkAuthState();
      
      if (user?.isEmailVerified) {
        toast.success('Email verified successfully!');
        navigate(from);
      } else {
        toast.error('Email not verified yet. Please check your inbox and click the verification link.');
      }
    } catch (error) {
      toast.error('Failed to check verification status. Please try again.');
    } finally {
      setVerifying(false);
    }
  };

  if (verifying) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-100 to-purple-100 flex items-center justify-center px-4">
        <div className="max-w-md w-full space-y-8 bg-white shadow-2xl rounded-2xl p-10 text-center">
          <Loader2 className="animate-spin h-8 w-8 text-indigo-600 mx-auto" />
          <h2 className="text-2xl font-semibold text-gray-900">Verifying your email...</h2>
          <p className="text-gray-600">Please wait while we confirm your email verification.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 to-purple-100 flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white shadow-2xl rounded-2xl p-10">
        <div className="text-center">
          <div className="mx-auto h-16 w-16 rounded-full bg-indigo-100 flex items-center justify-center">
            <MailIcon className="h-8 w-8 text-indigo-600" />
          </div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Verify Your Email
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            We've sent a verification link to{' '}
            <span className="font-medium text-indigo-600">{email}</span>
          </p>
        </div>

        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        )}

        <div className="mt-8 space-y-6">
          <div className="rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  Please check your inbox and click the verification link to continue.
                  Don't forget to check your spam folder!
                  If you haven't received it, you can request a new one.
                </p>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <button
              onClick={handleResendVerification}
              disabled={isResending}
              className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-lg text-white font-semibold bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 disabled:opacity-50"
            >
              {isResending ? (
                <>
                  <RefreshCw className="animate-spin -ml-1 mr-3 h-5 w-5" />
                  Resending...
                </>
              ) : (
                <>
                  <RefreshCw className="-ml-1 mr-3 h-5 w-5" />
                  Resend Verification Email
                </>
              )}
            </button>

            <button
              onClick={handleVerificationCheck}
              className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-lg text-white font-semibold bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-200"
            >
              I've Verified My Email
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="text-center mt-4">
          <button
            onClick={() => navigate('/login')}
            className="text-sm text-gray-600 hover:text-indigo-500 transition duration-200"
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;