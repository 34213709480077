import React, { useState } from 'react';
import { 
  ChevronDown, 
  ChevronUp, 
  BookOpen, 
  Target, 
  Code, 
  Laptop, 
  Rocket, 
  Award 
} from 'lucide-react';

const Curriculum = ({ topics, programName }) => {
  const [openTopicIndex, setOpenTopicIndex] = useState(null);

  const toggleTopic = (index) => {
    setOpenTopicIndex(openTopicIndex === index ? null : index);
  };

  // Dynamic icon selection based on topic or program
  const getTopicIcon = (title) => {
    const iconMap = {
      'Introduction': BookOpen,
      'Fundamentals': Code,
      'Advanced Techniques': Laptop,
      'Project Development': Rocket,
      'Capstone': Award,
      'Deployment': Target,
      default: BookOpen
    };

    const matchedIcon = Object.keys(iconMap).find(key => 
      title.toLowerCase().includes(key.toLowerCase())
    );

    return matchedIcon ? iconMap[matchedIcon] : iconMap.default;
  };

  if (!topics || topics.length === 0) {
    return (
      <div className="mt-12 bg-gray-50 p-8 rounded-2xl shadow-lg text-center">
        <BookOpen className="mx-auto mb-4 text-gray-400" size={48} />
        <p className="text-gray-600 text-lg">
          Curriculum details are not available for {programName || 'this program'}.
        </p>
      </div>
    );
  }

  return (
    <div className="mt-12 bg-gradient-to-br from-indigo-50 to-purple-50 p-8 rounded-2xl shadow-xl">
      <div className="flex items-center mb-8 space-x-4">
        <BookOpen className="text-indigo-600" size={36} />
        <h2 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600">
          {programName || 'Curriculum'} Overview
        </h2>
      </div>

      <div className="space-y-5">
        {topics.map((topic, index) => {
          const TopicIcon = getTopicIcon(topic.title);
          return (
            <div 
              key={index} 
              className="bg-white rounded-2xl shadow-md border border-gray-100 overflow-hidden transition-all duration-300 hover:shadow-lg"
            >
              <button
                onClick={() => toggleTopic(index)}
                className="w-full flex justify-between items-center p-5 text-left 
                  hover:bg-gray-50 transition-colors duration-200 
                  focus:outline-none focus:ring-2 focus:ring-purple-500"
                aria-expanded={openTopicIndex === index}
                aria-controls={`topic-details-${index}`}
              >
                <div className="flex items-center space-x-4">
                  <TopicIcon 
                    className={`text-${openTopicIndex === index ? 'purple' : 'indigo'}-500`} 
                    size={24} 
                  />
                  <span className={`text-xl font-semibold ${openTopicIndex === index ? 'text-purple-700' : 'text-gray-800'}`}>
                    {topic.title}
                  </span>
                </div>
                {openTopicIndex === index ? (
                  <ChevronUp className="text-purple-600" size={24} />
                ) : (
                  <ChevronDown className="text-gray-500" size={24} />
                )}
              </button>
              {openTopicIndex === index && (
                <div
                  id={`topic-details-${index}`}
                  className="p-5 pt-0 text-gray-600 animate-fade-in"
                >
                  <div className="border-t border-gray-100 pt-4">
                    {topic.details}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Curriculum;